import { AJAROOT } from "@/common/gdata/global.js";
import { Link, withRouter } from "react-router-dom";
export function DrawImage(eleid, width, height) {
  var vwidth = width;
  var vheight = height;
  var image = new Image();
  var ImgD = document.createElement(eleid);
  image.src = ImgD.src;

  if (image.width > 0 && image.height > 0) {
    if (image.width / image.height >= vwidth / vheight) {
      if (image.width > vwidth) {
        ImgD.width = vwidth;
        ImgD.height = (image.height * vwidth) / image.width;
      } else {
        ImgD.width = image.width;
        ImgD.height = image.height;
      }
      //ImgD.alt = image.width + "×" + image.height;
    } else {
      if (image.height > vheight) {
        ImgD.height = vheight;
        ImgD.width = (image.width * vheight) / image.height;
      } else {
        ImgD.width = image.width;
        ImgD.height = image.height;
      }
      // ImgD.alt = image.width + "×" + image.height;
    }
  }
}

export function getRndChr(e) {
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789",
    a = t.length,
    n = "";
  for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
}

export function IsPC() {
  var userAgentInfo = navigator.userAgent;

  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

export function Fenye(preurl, page, totalpage) {
  // var last = fenfen + "" + pagesize + "/页";
  let firstpage = page - 2;
  if (firstpage <= 0) firstpage = 1;
  let lastpage = page * 1 + 2;
  console.log(lastpage);
  console.log(totalpage);
  if (lastpage > totalpage) lastpage = totalpage;
  let rightstr = "";
  let firstarr = [];
  var o = 0;
  if (totalpage <= 5) {
    for (let a = 1; a <= totalpage; a++) {
      firstarr[o] = a;

      o++;
    }
  } else {
    for (let a = firstpage; a <= lastpage; a++) {
      firstarr[o] = a;

      o++;
    }
  }

  return (
    <div style={{ padding: "5px" }}>
      <span style={{ color: "#555", paddingRight: "30px" }}>
        Page {page} of {totalpage}
      </span>
      {firstpage > 1 && (
        <Link to={preurl + "&page=1"}>
          <span
            className={page * 1 === totalpage ? "cur" : "page"}
            /*  onClick={() => this.getHomeData(typeid, kwd, price, item)} /**/
          >
            &lt; &lt;
          </span>
        </Link>
      )}

      {firstarr.map((item, index) => {
        return (
          <Link to={preurl + "&page=" + item + ""} key={item}>
            <span
              key={index}
              className={page * 1 === item ? "cur" : "page"}
              /*  onClick={() => this.getHomeData(typeid, kwd, price, item)} /**/
            >
              {item}
            </span>
          </Link>
        );
      })}

      {lastpage < totalpage && (
        <Link to={preurl + "&page=" + totalpage + ""}>
          <span
            className={page * 1 === totalpage ? "cur" : "page"}
            /*  onClick={() => this.getHomeData(typeid, kwd, price, item)} /**/
          >
            &gt;&gt;
          </span>
        </Link>
      )}
    </div>
  );
}
export function LunBo(AJAROOT, arrimg) {
  return (
    <div id="myCarousel" className="carousel slide">
      <ol className="carousel-indicators">
        {arrimg.map((item, index) => {
          return (
            <li
              key={index}
              data-target="#myCarousel"
              data-slide-to={"" + index + ""}
              className={index === 0 ? "active" : ""}
            ></li>
          );
        })}
      </ol>

      <div className="carousel-inner">
        {arrimg.map((item, index) => {
          return (
            <div className={index === 0 ? "item active" : "item"} key={item}>
              <img src={AJAROOT + item} alt="Second slide" />
            </div>
          );
        })}
      </div>

      <a
        className="left carousel-control"
        href="#myCarousel"
        role="button"
        data-slide="prev"
      >
        <span
          className="glyphicon glyphicon-chevron-left"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="right carousel-control"
        href="#myCarousel"
        role="button"
        data-slide="next"
      >
        <span
          className="glyphicon glyphicon-chevron-right"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
}

export function getUserStatus(axios) {
  var userstatus = [];
  var geturl = AJAROOT + "/getaja/view.aspx?id=";

  axios.get(geturl).then((res) => {
    const result = res.data;
    // this.setState({}); /**/
  });
  return userstatus;
}
