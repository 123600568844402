import React, { useState } from "react";

import axios from "axios";

import { AJAROOT } from "@/common/gdata/global.js";

import "@/css/home.css";

import { Link } from "react-router-dom";
var test = "aaa";
var count3 = 0;
function Mytj(props) {
  console.log(props);
  const [count, aaa] = useState(0);
  const [username, setUsername] = useState("tom");
  const addcount = () => {
    let newCount = count;
    aaa((newCount += 1));
    count3 = count;
  };
  function getData() {
    test = "bbb";
    setUsername(test);
    console.log("fff" + username);
  }
  //getData();
  return (
    <>
      <p>{count3}</p>
      <button onClick={getData}>count++</button>
    </>
  );
}

export default Mytj;
