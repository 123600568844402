import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { AJAROOT } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import "@/css/user.css";
import UserTop from "@/pages/user/usertop.js";
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      zsxm: "",
      regtime: "",
      hba: 0,
      hbb: 0,
      hbc: 0,
      xfze: 0,
      totaljj: 0,
      error: "",
    };
  }
  render() {
    const {
      myusername,
      myusertype,
      myusergrade,
      style,
      usertype,
      usergrade,
      hb,
    } = this.props;

    if (myusername === "") {
      return (
        <div className="nologin">
          <Link to="/login">Please Login first. Login now!</Link>
        </div>
      );
    }
    var zsxm = this.state.zsxm;
    if (zsxm === "") zsxm = this.state.myusername;
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <UserTop
          gstyle={style}
          hba={this.state.hba}
          hb={hb}
          xfze={this.state.xfze}
          myusername={myusername}
          zsxm={zsxm}
          regtime={this.state.regtime}
          usergrade={this.state.usergrade}
        ></UserTop>
        <div
          className="row order"
          style={{
            height: "auto",
            border: "1px solid #bbb",
            borderRadius: "15px",
            backgroundColor: "#f6f6f6",
            margin: "20px 0px",
          }}
        >
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row">
              <div
                className="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                style={{
                  paddingLeft: "30px",
                  fontWeight: "bold",
                  paddingTop: "5px",
                }}
              >
                My Rental Orders
              </div>
              <div
                className="col-xs-6 col-sm-6 col-md-6 col-lg-6 textright"
                style={{
                  paddingRight: "30px",
                  paddingTop: "5px",
                }}
              >
                {/* <Link to="/user/myorder/9" key="9">
                  All<span className="iconfont">&#xe60b;</span>
                </Link> */}
              </div>
            </div>
            <div
              className="row"
              style={{
                paddingTop: "5px",
              }}
            >
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textcenter">
                <Link to="/user/myorder/9" key="9">
                  <div>
                    <span className="icon iconfont tb">&#xe652;</span>
                  </div>
                  <div>All</div>
                </Link>
              </div>

              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textcenter">
                <Link to="/user/myorder/0" key="1" isjs="0">
                  <div>
                    <span className="icon iconfont tb">&#xe67f;</span>
                  </div>
                  <div>No Deliver</div>
                </Link>
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textcenter">
                <Link to="/user/myorder/2" key="2">
                  <div>
                    <span className="icon iconfont tb">&#xe655;</span>
                  </div>
                  <div>No Return</div>
                </Link>
              </div>

              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textcenter">
                <Link to="/user/myorder/1" key="1">
                  <div>
                    <span
                      className="icon iconfont tb"
                      style={
                        {
                          //  fontSize: "40px",
                          //  position: "relative",
                          //  bottom: "7px",
                        }
                      }
                    >
                      &#xe614;
                    </span>
                  </div>
                  <div>Returned</div>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row menu nodis">
          <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
            <span className="iconfont">&#xe63f;</span>Pay Method
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright">
            <span className="iconfont">&#xe60b;</span>
          </div>
        </div>
        <div className="row menu">
          <Link to="/userup/1">
            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
              <span className="iconfont">&#xe68a;</span>Setup Account
            </div>
            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright">
              <span className="iconfont">&#xe60b;</span>
            </div>
          </Link>
        </div>
        <div className="row menu">
          <Link to="/userup/2">
            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
              <span className="iconfont">&#xe608;</span>Edit Password
            </div>
            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright">
              <span className="iconfont">&#xe60b;</span>
            </div>
          </Link>
        </div>
        <div className="row menu">
          <Link to="/logout">
            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
              <span className="iconfont">&#xe623;</span>Logout
            </div>
            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 textright">
              <span className="iconfont">&#xe60b;</span>
            </div>{" "}
          </Link>
        </div>
      </div>
    );
  }

  getData(myusername, ukey) {
    var that = this;
    var geturl =
      AJAROOT +
      "/getaja/user.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";
    console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
      // this.setState({}); /**/
      console.log(result);
      var zsxm = result.zsxm;
      var username = result.username;

      if (zsxm === "") zsxm = username;
      that.setState({
        zsxm: zsxm,
        regtime: result.regtime,
        hba: result.hba,
        hbb: result.hbb,
        hbc: result.hbc,
        xfze: result.xfze,
        totaljj: result.totaljj,
        usergrade: result.usergrade,
      });
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;

    this.getData(myusername, ukey);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
    style: state.getIn(["header", "style"]),
    //usertype: state.getIn(["header", "usertype"]),
    usergrade: state.getIn(["header", "usergrade"]),
    hb: state.getIn(["header", "hb"]),
  };
};

export default connect(mapStateToProps, null)(User);
