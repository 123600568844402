import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { AJAROOT, Dollar } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import "@/css/cart.css";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      isjs: "9",
      isqr: ["No", "Yes"],
      paystatus: ["No", "Yes"],
      status: ["No", "Yes"],
      issh: ["No", "Yes"],
      arrisjs: ["No", "Yes"],
      odlists: [],
      odsl: 0,
      error: "",
    };
  }
  render() {
    const { myusername, hb } = this.props;

    if (myusername === "") {
      return (
        <div className="nologin">
          <Link to="/login">Please Login first. Login now!</Link>
        </div>
      );
    }
    return (
      <div className="container wrap">
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              window.history.back(-1);
            }}
          >
            <span className="iconfont fanhui">&#xe625;</span>
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "22px", fontWeight: "bolder" }}
          >
            {this.state.title} {this.state.odsl} Items
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {/*  <Link to="/user/myorder/9" key="cart">
              <span className="icon iconfont" style={{ fontSize: "40px" }}>
                &#xe652;
              </span>
              <span>所有订单</span>
            </Link> */}
          </div>
        </div>
        {this.state.odlists.map((item, index) => {
          var province = item["province"];
          var city = item["city"];
          var country = item["country"];
          var diqu = "";
          // if (country !== "") diqu = province + "-" + city + "-" + country;
          // else if (city !== "") diqu = province + "-" + city;
          var address = item["address"];
          //if (address !== "") {
          //  if (diqu !== "") address = diqu + "，" + address;
          // }

          var zsxm = item["receiver"];
          var mobile = item["tel"];
          if (zsxm !== "") {
            address = zsxm + ", " + address;
          }
          if (city !== "") address = address + ", " + city;

          if (province !== "") address = address + ", " + province;
          if (mobile !== "") {
            address = address + ", Tel: " + mobile;
          }
          return (
            <div className="jscart" style={{ height: "auto" }} key={index}>
              <div className="row">
                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textcenter">
                  <div>
                    <img
                      src={AJAROOT + item["imgurl"]}
                      alt=""
                      className="img-thumbnail"
                    ></img>
                  </div>
                </div>
                <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 paneltitle jine">
                  <div className="row textleft"> #{item["odnum"]}</div>

                  <div className="row textleft">
                    Amount {Dollar}
                    {item["amount"]}
                  </div>
                  <div className="row textleft">
                    Check Status: {this.state.isqr[item["isqr"]]}
                  </div>
                  <div className="row textleft">
                    Pay Status: {this.state.paystatus[item["paystatus"]]}
                  </div>
                  <div className="row textleft">
                    Deliver Status: {this.state.status[item["status"]]}
                  </div>

                  <div className="row textleft">
                    Return Status: {this.state.issh[item["issh"]]}
                  </div>
                </div>
                <div
                  className="col-xs-1 col-sm-1 col-md-1 col-lg-1 close"
                  onClick={() => this.delItem(item["odnum"], index)}
                >
                  {item["paystatus"] === 0 && item["status"] === 0
                    ? "Delete"
                    : ""}
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 jine">
                  {item["detail"].map((item2, index2) => {
                    return (
                      <div
                        key={index2}
                        className={
                          item2["returned"] === "1" ? "row returndel" : "row"
                        }
                      >
                        <a href={"/detail/" + item2["cpid"]}>
                          {" "}
                          {Dollar}
                          {item2["price"]}/{item2["unit"]}
                          {item2["title"]} X {item2["cpnum"]}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <div className="row">
                <span className="bold">付款方式：</span>
                {hb[item["fkfs"]]}
              </div> */}
              <div className="row jine">
                <div>
                  + Renta Amount: {Dollar} {item["oriodamount"]}
                  <span
                    style={{ paddingLeft: "10px" }}
                    className={item["discount"] === 0 ? "nodis" : ""}
                  >
                    ({item["discount"] * 1 * 100}% Discount)
                  </span>
                </div>
                <div className={item["psfei"] > 0 ? "" : "nodis"}>
                  - Other Discount: {Dollar} {item["psfei"]}
                </div>
                <div className={item["psfei"] < 0 ? "" : "nodis"}>
                  + Other Discount: {Dollar} {item["psfei"] * -1}
                </div>
                <div>
                  + Deliver Fee: {Dollar} {item["yunfei"]}
                </div>
                <div>
                  + Insurance Fee: {Dollar} {item["insurancefee"]}
                </div>
                <div className={item["taxfee"] * 1 === 0 ? "nodis" : ""}>
                  + Tax Fee: {Dollar} {item["taxfee"]}
                </div>

                <div>
                  = Total: {Dollar} {item["amount"]}
                </div>
              </div>

              <div className="row jine">
                <span className="bold">Pick Up：</span>
                Pick up day is {item["pickupday"]}.
                <span
                  style={{ paddingLeft: "10px" }}
                  className={item["selfth"] === 0 ? "nodis" : ""}
                >
                  Self pick-up
                </span>
                <span
                  style={{ paddingLeft: "10px" }}
                  className={item["selfgh"] === 0 ? "nodis" : ""}
                >
                  Self return
                </span>
              </div>
              <div className="row jine">
                <span className="bold">Rental Term：</span>
                {item["period"]}, From {item["pickupday"]} To{" "}
                {item["expiretime"]}
              </div>

              <div className={item["renewtimes"] === 0 ? "nodis" : "row jine"}>
                <span className="bold">Renew：</span>
                {item["renewtimes"]} Times
                <span style={{ paddingLeft: "10px" }}>
                  (Orirental Term:{item["oriperiod"]}, History Total {Dollar}
                  {item["totalamount"]})
                </span>
              </div>

              <div
                className={
                  item["selfth"] === 1 && item["selfgh"] === 1
                    ? "nodis"
                    : "row jine"
                }
              >
                <span className="bold">Delivery Address：</span>
                {address}
              </div>
              <div className={item["remark"] === "" ? "nodis" : "row jine"}>
                <span className="bold">Other Infomation：</span>
                {item["remark"]}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  getData(myusername, ukey, isjs) {
    let title = "All Order";
    if (isjs === "1") {
      title = "Returned";
    } else if (isjs === "0") {
      title = "No Delivery";
    } else if (isjs === "2") {
      title = "No Retun";
    }
    var geturl =
      AJAROOT +
      "/getaja/myorder.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&isjs=" +
      isjs +
      "";
    console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
      // this.setState({}); /**/
      console.log(result);
      this.setState({
        odlists: result["odlists"],
        odsl: result["odsl"],
        title: title,
      });
    });
  }
  componentDidMount() {
    $("#bottomer").css("display", "none");
    const { myusername, ukey } = this.props;
    const { isjs } = this.props.match.params;
    this.setState({
      isjs: isjs,
    });

    this.getData(myusername, ukey, isjs);
    document.title = "My Rental Orders";
  }

  componentWillUnmount() {
    $("#bottomer").css("display", "none");
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    //console.log(this.props);
    //const { myusername, ukey } = this.props;
    const { isjs } = this.props.match.params;
    //console.log("ff" + isjs);
    if (isjs !== this.state.isjs) {
      // this.getData(myusername, ukey, isjs);
      // this.setState({
      //   isjs: isjs,
      // });
      // this.getData(this.props.myusername, this.props.ukey);
    }
  }

  delItem(odnum, index) {
    var geturl =
      AJAROOT +
      "/getaja/myorder.aspx?u=" +
      this.props.myusername +
      "&ukey=" +
      encodeURIComponent(this.props.ukey) +
      "&doit=del&onlydo=1&odnum=" +
      odnum +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;
      // this.setState({}); /**/
      if (result.r === 1) {
        let odlists = this.state.odlists;
        odlists.splice(index, 1);
        let odsl = odlists.length;
        this.setState({
          lists: odlists,
          odsl: odsl,
        });
      }
    });

    //const action = {
    //  type: "change_cart_num",
    //  cartnum: cartlist.length,
    //};
    // this.props.changeCartNum(action);
  }

  doOrder(odnum, index, action) {
    var geturl =
      AJAROOT +
      "/getaja/myorder.aspx?u=" +
      this.props.myusername +
      "&ukey=" +
      encodeURIComponent(this.props.ukey) +
      "&doit=" +
      action +
      "&justdo=1&odnum=" +
      odnum +
      "";
    console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
      // this.setState({}); /**/
      if (result.r === 1) {
        //  console.log(result);
        let odlists = this.state.odlists;
        if (action === "fk") odlists[index]["paystatus"] = 1;
        else if (action === "sh") odlists[index]["issh"] = 1;
        //  let odsl = odlists.length;
        this.setState({
          lists: odlists,
        });
      } else {
        const r = result.s;
        alert(r);
      }
    });
  }
}
const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    ukey: state.getIn(["gdata", "ukey"]),
  };
};
const mapDispatch = (dispath) => ({
  changeCartNum(action) {
    dispath(action);
  },
});
export default connect(mapStateToProps, mapDispatch)(Cart);
