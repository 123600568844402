import React, { Component } from "react";

import $ from "jquery";
import queryString from "query-string";

import { LoginWrapper, LoginBox, Input, Button, PostResult } from "./style";

import { AJAROOT } from "@/common/gdata/global.js";
import "@/css/global.css";
class Confirmemail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      postresult: "",
      posterror: "",
    };
  }
  render() {
    // const { postresult } = this.props;
    //  console.log("render");
    return (
      <div className="container wrap">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <span
              className={this.state.posterror === "" ? "" : "nodis"}
              style={{ fontSize: "25px" }}
            >
              {this.state.postresult}
            </span>
            <span className="loginerror" style={{ fontSize: "25px" }}>
              {this.state.posterror}
            </span>
          </div>
        </div>
      </div>
    );
    /*  <LoginWrapper>
        <LoginBox style={{ height: "220px" }}>
          <Button onClick={() => this.login2()}>Request new password</Button>

       
          <PostResult id="result2">
            <span className={this.state.posterror === "" ? "" : "nodis"}>
              {this.state.postresult}
            </span>
            <span className="loginerror">{this.state.posterror}</span>
          </PostResult>
        </LoginBox>
      </LoginWrapper> */
  }
  componentDidMount() {
    var { token } = queryString.parse(this.props.location.search);
    console.log(token);
    if (token == null) token = "";

    this.setState({
      token: token,
    });
    this.login2(token);
  }
  login2(token) {
    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Checking...",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";

    const this2 = this;
    var error = "";

    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        posterror: error,
      });
    } else {
      let url =
        AJAROOT + "/getaja/xx.aspx?action=resetpass&token=" + encodeURIComponent(token) + "";
      console.log(url);
      $.ajax({
        type: "GET",
        dataType: "json",
        url: url,
      })
        .done(function (data) {
          var r = data.r;
          var msg = data.s;
          console.log(data);
          if (r === "1") {
            //  this.postresult = "登陆成功！";
            // this2.props.history.push("/");
            this2.setState({
              posterror: "",
              postresult: "Your email confirmed!",
            });
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              posterror: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }
}

export default Confirmemail;
