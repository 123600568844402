import React, { Component } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import { AJAROOT } from "@/common/gdata/global.js";
import { LbTitle, Pages } from "@/pages/type/style.js";

import "@/css/newslist.css";

class NewsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagesize: 30,
      newslist: [],
      totalpage: 0,

      catename: "",
      cateod: 0,
      cateid: "",

      kwd: "",

      page: "",
    };
  }
  Page(typeid, kwd, page, totalpage) {
    // var last = fenfen + "" + pagesize + "/页";
    let firstpage1 = page - 2;
    if (firstpage1 <= 0) firstpage1 = 1;
    let lastpage1 = page + 2;
    if (lastpage1 <= 5) lastpage1 = 5;
    if (lastpage1 > totalpage) lastpage1 = totalpage;
    let firstarr = [];
    var o = 0;
    for (let a = firstpage1; a <= lastpage1; a++) {
      firstarr[o] = a;

      o++;
    }

    let lastarr = [];
    var middstr = "";
    if (totalpage >= 6) {
      middstr = "...";
      let firstpage2 = totalpage - 2;
      if (firstpage2 <= firstarr[o - 1]) {
        firstpage2 = firstarr[o - 1] + 1;
        //   console.log(firstpage2);
        middstr = "";
      }
      if (firstpage2 === firstarr[o - 1] + 1) {
        middstr = "";
      }
      let lastpage2 = totalpage;

      o = 0;
      for (let a = firstpage2; a <= lastpage2; a++) {
        lastarr[o] = a;
        o++;
      }
    }
    return (
      <div style={{ padding: "5px" }}>
        <span style={{ color: "#555", paddingRight: "30px" }}>
          Page {page} Of {totalpage}
        </span>

        {firstarr.map((item, index) => {
          return (
            <span
              key={index}
              className={page * 1 === item ? "cur" : "page"}
              onClick={() => this.getHomeData(typeid, kwd, item)}
            >
              {item}
            </span>
          );
        })}

        {middstr}

        {lastarr.map((item, index) => {
          return (
            <span
              key={index}
              className={page === item ? "cur" : "page"}
              onClick={() => this.getHomeData(typeid, kwd, item)}
            >
              {item}
            </span>
          );
        })}
      </div>
    );
  }
  /*  Fenye(typeid, kwd, page, lastpage, nextpage, totalpage) {
    // var last = fenfen + "" + pagesize + "/页";

    return (
      <div style={{ padding: "5px" }}>
        <span style={{ color: "#555", paddingRight: "30px" }}>
          Page {page} Of {totalpage}
        </span>

        <span
          className={page === 1 ? "cur" : "page"}
          onClick={() => this.getHomeData(typeid, kwd, 1)}
        >
          First
        </span>
        <span
          className={page <= lastpage ? "cur" : "page"}
          onClick={() => this.getHomeData(typeid, kwd, lastpage)}
        >
          Pre
        </span>
        <span
          className={page >= nextpage ? "cur" : "page"}
          onClick={() => this.getHomeData(typeid, kwd, nextpage)}
        >
          Next
        </span>
        <span
          className={page >= totalpage ? "cur" : "page"}
          onClick={() => this.getHomeData(typeid, kwd, totalpage)}
        >
          Last
        </span>
       
      </div>
    );
  } */
  render() {
    // console.log(GetTypeId());

    var nextpage = this.state.page * 1 + 1;

    if (nextpage > this.state.totalpage * 1) nextpage = this.state.totalpage;
    var lastpage = this.state.page * 1 - 1;
    if (lastpage < 1) lastpage = 1;
    //JSON.parse(JSON.stringify(cpLb)).le

    // const url = "/type/" + typeid + "/{page}";
    //const yestr = Fenye2(typeid, lastpage, nextpage, totalpage);
    const styles = {
      lbtitle: {
        fontSize: "16px",

        padding: "10px",
        color: "#111",
        fontWeight: "800",
      },
    };

    return (
      <div className="container wrap">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row">
              <LbTitle>
                <span>
                  <a href="/">Home</a>
                </span>

                <span>
                  /{" "}
                  <a href={"/newslist?cateid=" + this.state.cateid + ""}>
                    {this.state.catename}
                  </a>
                </span>
              </LbTitle>
            </div>
            <div className="row big">{this.state.catename}</div>

            <div
              gutter={24}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap ",
              }}
            >
              {this.state.newslist.map((item2, index) => {
                return (
                  <div
                    className={
                      this.state.cateod >= 100 ? "newswindow" : "newslist"
                    }
                    key={item2["id"]}
                  >
                    <div className="cptp">
                      <Link
                        to={"/newsview?id=" + item2["id"] + ""}
                        key={item2["id"]}
                      >
                        <img
                          style={{ width: "100%" }}
                          src={AJAROOT + item2["newsimg"]}
                          key={index}
                        />
                      </Link>
                    </div>
                    <div className="cptext">
                      <div className="cptitle">
                        <Link
                          to={"/newsview?id=" + item2["id"] + ""}
                          key={item2["id"]}
                        >
                          {item2["title"]}
                          <span
                            style={{ paddingLeft: "20px", fontSize: "15px" }}
                          >
                            {item2["systime"]}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <Pages>
                {this.Page(
                  this.state.cateid,
                  this.state.kwd,

                  this.state.page,

                  this.state.totalpage
                )}
              </Pages>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getHomeData(cateid, kwd, page) {
    var geturl =
      AJAROOT +
      "/getaja/news_list.aspx?cid=" +
      cateid +
      "&key=" +
      kwd +
      "&page=" +
      page +
      "&u=";

    axios.get(geturl).then((res) => {
      const result = res.data;

      // this.setState({}); /**/
      this.setState({
        newslist: result["news"]["newslist"],
        catename: result["news"]["catename"],
        cateod: result["news"]["cateod"],
        totalpage: result["news"]["totalpage"],
        totalitem: result["news"]["totalitem"],

        cateid: cateid,
        kwd: kwd,
        page: page,
      });
      let title = result["news"]["catename"];

      if (kwd !== "") title = kwd + "";

      if (title.substring(title.length - 1) === ",")
        title = title.substring(0, title.length - 1);

      document.title = title;

      //   this.props.changeHomeData(action);
    });
  }
  componentDidMount() {
    // console.log(this.props);
    // const { typeid } = this.props.match.params;
    var { cateid, kwd } = queryString.parse(this.props.location.search);

    if (cateid == null) cateid = "";
    if (kwd == null) kwd = "";

    //this.setState({
    //  kwd: kwd,
    // });
    this.getHomeData(cateid, kwd, 1);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    // const { typeid } = this.props.match.params;
    var { cateid, kwd } = queryString.parse(this.props.location.search);

    if (cateid == null) cateid = "";
    if (kwd == null) kwd = "";
    if (cateid !== this.state.cateid || kwd != this.state.kwd) {
      //  this.setState({
      //   kwd: kwd,
      //  });
      this.getHomeData(cateid, kwd, 1);
    }
  }
}

export default NewsList;
