import React, { Component } from "react";
import { connect } from "react-redux";

import { SiteName } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import "@/css/global.css";
class Bottom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { bnewlist } = this.props;
    /*  */
    return (
      <div
        id="bottomer"
        className="container"
        style={{
          width: "100%",
          backgroundColor: "#333333",
          color: "white",
        }}
      >
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <span style={{ fontSize: "18px", color: "#eee" }}>OUR COMPANY</span>
            <ul>
              {bnewlist.map((item, index) => {
                return (
                  <li key={item["id"]}>
                    <a href={"/newsview?id=" + item["id"] + ""}>
                      {item["title"]}
                    </a>
                  </li>
                );
              })}
              {/* 
              <li>
                <a href="/news?id=1">About Us</a>
              </li>

              <li>
                <a href="/news?id=1">How to Order</a>
              </li>
              <li>
                <a href="/news?id=1">Uesd Furnitusers</a>
              </li>

              <li>
                <a href="/news?id=1">Shipping & Return</a>
              </li>
              <li>
                <a href="/news?id=1">Privacy Policy</a>
              </li>
              <li>
                <a href="/news?id=1">Contact Us</a>
              </li>
 */}
              <li>
                <a href="/newslist?cateid=gallery">Gallery</a>
              </li>
            </ul>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <span style={{ fontSize: "18px", color: "#eee" }}>ACCOUNT</span>
            <ul>
              <li>
                <a href="/login">Login</a>
              </li>
              <li>
                <a href="/register">Sign up</a>
              </li>
              <li>
                <a href="/user">My account</a>
              </li>
              <li>
                <a href="/cart">My Cart</a>
              </li>
            </ul>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <span style={{ fontSize: "18px", color: "#eee" }}>
              {SiteName.toUpperCase()}
            </span>
            <div
              style={{ fontSize: "14px", color: "#999", paddingTop: "20px" }}
            >
              <p>41 Passmore Ave, Unit 1 Scarborough, ON</p>
              <p>M1V 4T1</p>
              <p>Tel: 647-674-5678 gracioushome.ca@gmail.com</p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <div style={{ fontSize: "14px", color: "#999" }}>
              <a title="Facebook" href="//facebook.com/" target="_social"></a>
              <a title="Twitter" href="//twitter.com/" target="_social"></a>
              <a title="Youtube" href="//youtube.com/" target="_social"></a>
              <a title="Linkedin" href="//linkedin.com/" target="_social"></a>
            </div>
          </div>
        </div>
        <div className="row" style={{ backgroundColor: "#111111" }}>
          <div style={{ fontSize: "14px", color: "#999" }}>
            ©2022 {SiteName} All Rights Reserved.
          </div>
        </div>
      </div>
    );
  }
}
const mapState = (state) => ({
  bnewlist: state.getIn(["home", "bnewlist"]),
  //  dflblist: state.getIn(["home", "dflblist"]),
});
export default connect(mapState, null)(Bottom);
