import Loadable from "react-loadable";
const LoadableComponent = Loadable({
  loader: () => import("./index"),
  loading() {
    return <div className="loading">Loading...</div>;
  },
});

const todo = () => <LoadableComponent />;
export default todo;
