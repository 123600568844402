import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import "@/css/user.css";
import UserTop from "@/pages/user/usertop.js";
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      uptype: "1",
      title: "",
      zsxm: "",
      regtime: "",
      hba: 0,
      hbb: 0,
      hbc: 0,
      xfze: 0,
      totaljj: 0,
      error: "",
      upzsxm: "",
      upgzhnickname: "",
      upmobile: "",
      upcomname: "",
      address: "",
      uppassword: "",
      uppassword2: "",
      postresult: "",
    };
  }
  render() {
    const {
      myusername,
      myusertype,
      myusergrade,
      style,
      usertype,
      usergrade,
      hb,
    } = this.props;

    if (myusername === "") {
      return (
        <div className="nologin">
          <Link to="/login">Please Login first. Login now!</Link>
        </div>
      );
    }
    var zsxm = this.state.zsxm;
    if (zsxm === "") zsxm = this.state.myusername;
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px" }}
      >
        <UserTop
          gstyle={style}
          hba={this.state.hba}
          hb={hb}
          totaljj={this.state.totaljj}
          xfze={this.state.xfze}
          zsxm={zsxm}
          regtime={this.state.regtime}
          usertype={usertype[myusertype]}
          myusername={myusername}
          usergrade={usergrade[myusergrade]}
        ></UserTop>

        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              window.history.back(-1);
            }}
          >
            <span className="iconfont fanhui">&#xe625;</span>
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "22px", fontWeight: "bolder" }}
          >
            {this.state.title}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form">
            <div className={this.state.uptype === "1" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 ">
                First Name:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 ">
                <input
                  value={this.state.upgzhnickname}
                  onChange={this.changeFirstName.bind(this)}
                />
              </div>
            </div>
            <div className={this.state.uptype === "1" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                Last Name:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <input
                  value={this.state.upzsxm}
                  onChange={this.changeZsxm.bind(this)}
                />
              </div>
            </div>

            <div className={this.state.uptype === "1" ? "row" : " nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                Telephone:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <input
                  value={this.state.upmobile}
                  onChange={this.changeMobile.bind(this)}
                />
              </div>
            </div>
            <div className={this.state.uptype === "1" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                Company:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <input
                  value={this.state.upcomname}
                  onChange={this.changeComname.bind(this)}
                />
              </div>
            </div>
            <div className={this.state.uptype === "1" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                Address:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <input
                  style={{ width: "260px" }}
                  value={this.state.address}
                  onChange={this.changeAddress.bind(this)}
                />
              </div>
            </div>
            <div className={this.state.uptype === "2" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                Password:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <input
                  type="password"
                  value={this.state.uppassword}
                  onChange={this.changePassword.bind(this)}
                />
              </div>
            </div>
            <div className={this.state.uptype === "2" ? "row" : "nodis"}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                Retype Password:
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <input
                  type="password"
                  value={this.state.uppassword2}
                  onChange={this.changePassword2.bind(this)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter">
                <button
                  onClick={() => this.submit()}
                  style={{ width: "50%", marginTop: "10px" }}
                >
                  Submit
                </button>
              </div>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textcenter"></div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter"
                style={{ width: "70%", marginTop: "10px" }}
              >
                {this.state.postresult}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  changeAddress(e) {
    let val = e.target.value;
    this.setState(() => {
      return {
        address: val,
      };
    });
  }
  changeZsxm(e) {
    let val = e.target.value;
    this.setState(() => {
      return {
        upzsxm: val,
      };
    });
  }
  changeComname(e) {
    let val = e.target.value;
    this.setState(() => {
      return {
        upcomname: val,
      };
    });
  }
  changeFirstName(e) {
    let val = e.target.value;
    this.setState(() => {
      return {
        upgzhnickname: val,
      };
    });
  }
  changeMobile(e) {
    let val = e.target.value;
    this.setState(() => {
      return {
        upmobile: val,
      };
    });
  }
  changePassword(e) {
    let val = e.target.value;
    this.setState(() => {
      return {
        uppassword: val,
      };
    });
  }
  changePassword2(e) {
    let val = e.target.value;
    this.setState(() => {
      return {
        uppassword2: val,
      };
    });
  }
  submit() {
    const { myusername, ukey } = this.props;

    // document.getElementById("result2").style.color = "#000000";
    console.log(this);
    this.setState({
      postresult: "Please waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    var mobile = this.state.upmobile;
    var zsxm = this.state.upzsxm;
    var gzhnickname = this.state.upgzhnickname;
    var comname = this.state.upcomname;
    var address = this.state.address;
    var password = this.state.uppassword;
    var password2 = this.state.uppassword2; //#region ;
    const postdata = {
      uid: myusername,
      mobile: mobile,
      zsxm: zsxm,
      gzhnickname: gzhnickname,
      comname: comname,
      password: password,
      address: address,
      uptype: this.state.uptype,
    };
    const this2 = this;
    var error = "";
    if (this.state.uptype == "2") {
      if (password !== password2) {
        error = "Retype password doesn't math";
      }
      if (password === "") error = "Please fill in password";
    }
    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=upinfo&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (data) {
          var r = data.r;
          var msg = data.msg;

          if (r === "1") {
            //  this.postresult = "登陆成功！";

            this2.setState({
              postresult: "Success!",
            });

            this2.props.history.push("/user");
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              postresult: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }
  getData(myusername, ukey) {
    var that = this;
    var geturl =
      AJAROOT +
      "/getaja/user.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;
      // this.setState({}); /**/

      var zsxm = result.zsxm;

      that.setState({
        upzsxm: zsxm,
        upmobile: result.mobile,
        upgzhnickname: result.nickname,
        upcomname: result.comname,
        address: result.address,
        zsxm: zsxm,
        mobile: result.mobile,
        regtime: result.regtime,
        hba: result.hba,
        hbb: result.hbb,
        hbc: result.hbc,
        xfze: result.xfze,
        totaljj: result.totaljj,
      });
    });
  }
  componentDidMount() {
    const { myusername, ukey } = this.props;
    const { t } = this.props.match.params;
    var title = "Set Account infomation";
    var uptype = "1";
    if (t === "2") {
      title = "Edit password";
      uptype = "2";
    }
    this.setState({
      title: title,
      uptype: uptype,
    });
    this.getData(myusername, ukey);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
    style: state.getIn(["header", "style"]),
    usertype: state.getIn(["header", "usertype"]),
    usergrade: state.getIn(["header", "usergrade"]),
    hb: state.getIn(["header", "hb"]),
  };
};

export default connect(mapStateToProps, null)(User);
