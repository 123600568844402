import React, { Component } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import { AJAROOT } from "@/common/gdata/global.js";
import { LbTitle, Pages } from "@/pages/type/style.js";

import "@/css/newslist.css";

class NewsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      newstitle: "",
      newsimg: "",
      newsdesc: "",

      systime: "",
    };
  }

  render() {
    // console.log(GetTypeId());

    const styles = {
      lbtitle: {
        fontSize: "16px",

        padding: "10px",
        color: "#111",
        fontWeight: "800",
      },
    };

    return (
      <div className="container wrap">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="row">
              <LbTitle>
                <span>
                  <a href="/">Home</a>
                </span>
                <span>
                  /{" "}
                  <a href={"/newslist?cateid=" + this.state.cateid + ""}>
                    {this.state.catename}
                  </a>
                </span>
              </LbTitle>
            </div>
            <div className="row big" style={{ paddingTop: "15px" }}>
              {this.state.newstitle}
            </div>

            <div className="newsview">
              <div className="newsimg">
                <img
                  style={{ width: "100%" }}
                  src={AJAROOT + this.state.newsimg}
                />
              </div>
              <div className="row textleft">
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.newsdesc }}
                ></div>
              </div>

              <div className="systime textright">{this.state.systime}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getHomeData(id) {
    var geturl = AJAROOT + "/getaja/news_con.aspx?newsid=" + id;
    console.log(geturl);
    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);
      // this.setState({}); /**/
      this.setState({
        id: id,
        newstitle: result["newstitle"],
        catename: result["catename"],
        cateid: result["cateid"],
        newsdesc: result["newsdesc"],
        newsimg: result["newsimg"],
        systime: result["systime"],
      });
      let newstitle = result["newstitle"];

      document.title = newstitle;

      //   this.props.changeHomeData(action);
    });
  }
  componentDidMount() {
    // console.log(this.props);
    // const { typeid } = this.props.match.params;
    var { id } = queryString.parse(this.props.location.search);

    if (id == null) id = "";

    //this.setState({
    //  kwd: kwd,
    // });
    this.getHomeData(id);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {}
  componentDidUpdate() {
    // const { typeid } = this.props.match.params;
    var { id } = queryString.parse(this.props.location.search);

    if (id == null) id = "";

    if (id !== this.state.id) {
      //  this.setState({
      //   kwd: kwd,
      //  });
      this.getHomeData(id);
    }
  }
}

export default NewsView;
