import React, { Component } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter } from "react-router-dom";
import Header from "./common/header/index.js";
import Bottom from "./common/bottom/index.js";
import Home from "./pages/home/loadable.js";
import Detail from "./pages/detail/index.js";
import Findpass from "./pages/login/findpass.js";
import Resetpass from "./pages/login/resetpass.js";
import Confirmemail from "./pages/login/confirmemail.js";
import Login from "./pages/login/loadable.js";
import Logout from "./pages/logout/index.js";
import User from "./pages/user/index.js";
import UserUp from "./pages/user/userup/index.js";
import Mytj from "./pages/user/mytj.js";
import Hblist from "./pages/user/hblist.js";
import Cart from "./pages/cart/index.js";
import Alert from "./pages/alert/index.js";
import Cart2 from "./pages/cart2/index.js";
import Myorder from "./pages/user/myorder/index.js";
import Register from "./pages/register/loadable.js";
import Type from "./pages/type/loadable.js";
import NewsType from "./pages/news/newslist.js";
import NewsView from "./pages/news/newsview.js";
import store from "./store/index.js";

class App extends Component {
  render() {
    //console.log(window.location);
    //window.location.href = AJAROOT;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Header />
          <Route path="/" exact component={Home} />
          <Route path="/detail/:cpid" exact component={Detail} />
          <Route path="/register" exact component={Register} />
          <Route path="/login" exact component={Login} />
          <Route path="/findpass" exact component={Findpass} />
          <Route path="/resetpass" exact component={Resetpass} />
          <Route path="/confirmemail" exact component={Confirmemail} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/user" exact component={User} />
          <Route path="/user/mytj" exact component={Mytj} />
          <Route path="/user/hblist" exact component={Hblist} />
          <Route path="/cart" exact component={Cart} />
          <Route path="/cart2" exact component={Cart2} />
          <Route path="/alert" exact component={Alert} />
          <Route path="/user/myorder/:isjs" component={Myorder} />
          {/*  <Route path="/type/:typeid" component={Type}></Route> */}
          <Route path="/type" component={Type}></Route>
          <Route path="/newslist" component={NewsType}></Route>
          <Route path="/newsview" component={NewsView}></Route>
          <Route path="/userup/:t" exact component={UserUp}></Route>
          <Bottom />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
