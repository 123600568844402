import React, { Component, Dimensions2 } from "react";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Logo, NavSearch, SearchWrapper, Nav2Item } from "./style";
import cookie from "react-cookies";
import * as actionCreators from "./store/actionCreator";
import { Link } from "react-router-dom";
import "@/css/global.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      kwd: "",
      type2: [],
      shownav: false,
    };
  }

  render() {
    const { myusername, cartnum, cpLb } = this.props;

    let width = $(window).width();
    let minwidth = 680;
    let smalldevice = 1;
    if (width > minwidth) smalldevice = 0;
    // document.getElementById("searchword").value = this.props.kwd;
    // this.getHomeData(myusername);
    // console.log(this.state.type2);

    return (
      <div>
        <div className="container top">
          <div className="row ">
            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 ">
              <Link to="/">
                <Logo></Logo>
              </Link>
            </div>

            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 textright">
              <div className="row">
                {myusername === "" ? (
                  <Nav2Item className="right ">
                    <Link to="/login" style={{ paddingRight: "20px" }}>
                      Login
                    </Link>
                    <Link to="/register">Sign Up</Link>
                  </Nav2Item>
                ) : (
                  <Nav2Item className="right">
                    <Link to="/cart">
                      <span className="iconfont tb">&#xe70b;</span>
                      <span className="cartnum">{cartnum}</span>
                    </Link>{" "}
                    <Link to="/user">
                      <span className="iconfont tb user">&#xe61a;</span>
                    </Link>{" "}
                    <Link to="/logout">
                      <span className="iconfont tb">&#xe623;</span>
                    </Link>{" "}
                  </Nav2Item>
                )}
              </div>
              <div className="row ">
                <div
                  className="floatright"
                  style={{
                    marginLeft: "20px",
                    height: "50px",
                    lineHeight: "50px",
                    marginRight: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "20px",

                      color: "#0071ab",
                      padding: "0px 10px",
                    }}
                    className="iconfont"
                  >
                    &#xe7bf;
                  </span>
                  1-416-297-8088
                </div>
                <div
                  className="floatright"
                  style={{ marginLeft: "20px", width: "250px" }}
                >
                  <SearchWrapper>
                    <NavSearch
                      id="searchword"
                      //value={this.props.kwd}

                      ref={(input) => {
                        this.searchword = input;
                      }}
                      onKeyDown={(e) => this.onKeyPressed(e)}
                    ></NavSearch>

                    <span
                      style={{
                        fontSize: "20px",
                        fontWight: "bold",
                        cursor: "pointer",
                        color: "#0071ab",
                      }}
                      className="iconfont zoom"
                      onClick={() => this.handleSearch()}
                    >
                      &#xe637;
                    </span>
                  </SearchWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#111111",
            width: "100%",
            marginTop: "8px",
            paddingLeft: "30px",
            paddingRight: "30px",
            fontColor: "white",
          }}
        >
          <div
            className={smalldevice === 1 ? "" : "nodis"}
            style={{ height: "50px" }}
            onClick={() => this.ShowNav()}
          >
            <span style={{ color: "grey", fontSize: "30px" }}>≡</span>
          </div>
          <div
            className={
              smalldevice === 0 || this.state.shownav === 1 ? "nav" : "nodis"
            }
          >
            <ul>
              {cpLb.map((item) => {
                let lbid = item["id"];
                let navid = "nav" + lbid;
                let subid = "sub" + lbid;
                let flbitem = item["flb"];

                return (
                  <li
                    key={lbid}
                    className="li"
                    onMouseOver={() => this.ShowMenu(subid, navid)}
                    onMouseOut={() => this.HidMenu(subid, navid)}
                  >
                    <a
                      href={"/type?typeid=" + lbid + ""}
                      className="nava"
                      id={navid}
                    >
                      {item["name"].toUpperCase()}
                    </a>
                    <div className="sub" id={subid}>
                      {flbitem.map((item2, index) => {
                        return (
                          <p key={index}>
                            <a href={"/type?typeid=" + item2["id"] + ""}>
                              {item2["name"].toUpperCase()}
                            </a>
                          </p>
                        );
                      })}
                    </div>
                  </li>
                );
              })}

              <li
                className="li"
                onMouseOver={() => this.ShowMenu("sub80", "nav80")}
                onMouseOut={() => this.HidMenu("sub80", "nav80")}
              >
                <a href="/newsview?id=17" id="nav80">
                  USED FURNITURES
                </a>
                <div className="sub" id="sub80"></div>
              </li>
              <li
                className="li"
                onMouseOver={() => this.ShowMenu("sub81", "nav81")}
                onMouseOut={() => this.HidMenu("sub81", "nav81")}
              >
                <a href="/newsview?id=19" id="nav81">
                  ABOUT US
                </a>
                <div className="sub" id="sub81"></div>
              </li>

              <li
                className="li"
                onMouseOver={() => this.ShowMenu("sub82", "nav82")}
                onMouseOut={() => this.HidMenu("sub82", "nav82")}
              >
                <a href="/newsview?id=18" id="nav82">
                  HOW TO ORDER
                </a>
                <div className="sub" id="sub82"></div>
              </li>

              <li
                className="li"
                onMouseOver={() => this.ShowMenu("sub83", "nav83")}
                onMouseOut={() => this.HidMenu("sub83", "nav83")}
              >
                <a href="/newsview?id=9" id="nav83">
                  CONTACT US
                </a>
                <div className="sub" id="sub83"></div>
              </li>

              <li
                className="li"
                onMouseOver={() => this.ShowMenu("sub84", "nav84")}
                onMouseOut={() => this.HidMenu("sub84", "nav84")}
              >
                <a href="/newslist?cateid=gallery" id="nav84">
                  GALLERY
                </a>
                <div className="sub" id="sub84"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  onKeyPressed(e) {
    if (e.keyCode == 13) this.handleSearch();
  }
  getHomeData() {
    const { myusername, ukey } = this.props;
    if (myusername === null) myusername = "";

    let rememberusername = "";
    let rememberopenid = "";
    if (myusername === "") {
      rememberusername = cookie.load("username");
      rememberopenid = cookie.load("openid");
      if (rememberusername == null) rememberusername = "";
      if (rememberopenid == null) rememberopenid = "";
    }
    // console.log("rememberusername" + rememberusername);
    // console.log("rememberopenid" + rememberopenid);
    var geturl =
      AJAROOT +
      "/getaja/index.aspx?id=1&u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&rememberusername=" +
      rememberusername +
      "&rememberopenid=" +
      rememberopenid +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;
      // console.log(result);
      const lgstatus = result["lgstatus"];
      const action = {
        type: "change_home_data",
        cpLb: result["lblist"],
        adList: result["ads"],
        ggList: result["ggList"],
        homeregad: result["homeregad"],
        bnewlist: result["bnewlist"],
        //  dflblist: result["dflblist"],
      };
      this.props.changeHomeData(action);
      if (lgstatus === "logout") {
        //clear cookie
        const action = {
          type: "change_login_data",
          username: "",
          ukey: "",
          usertype: 0,
          usergrade: 1,
          cartnum: 0,
        };
        this.props.changeLoginData(action);
      } else if (lgstatus === "reload") {
        const action = {
          type: "change_login_data",
          username: result["uname"],
          ukey: result["ukey"],
          usertype: result["usertype"],
          usergrade: result["usergrade"],
          cartnum: result["cartnum"],
        };
        this.props.changeLoginData(action);
      }
    });
  }
  ShowNav() {
    let shownav = this.state.shownav;
    if (shownav === 1) shownav = 0;
    else shownav = 1;
    this.setState({
      shownav: shownav,
    });
  }
  ShowMenu(id, id2) {
    document.getElementById(id).style.display = "block";

    document.getElementById(id2).style.backgroundColor = "#aaaaaa";
  }
  HidMenu(id, id2) {
    document.getElementById(id).style.display = "none";
    document.getElementById(id2).style.backgroundColor = "#111111";
  }
  componentDidMount() {
    this.getHomeData();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  componentWillUpdate() {
    //  document.getElementById("searchword").value = "";
    //  document.getElementById("searchword").value = "";
  }
  componentDidUpdate() {
    //console.log("componentWillUnmount" + this.props.kwd);
    //  console.log("componentWillUnmount" + this.state.kwd);
    //document.getElementById("searchword").value = this.props.kwd;
    var { kwd } = queryString.parse(this.props.location.search);
    if (kwd == null) kwd = "";

    if (this.props.kwd !== kwd) {
      // console.log("ffff" + kwd);
      document.getElementById("searchword").value = kwd;
    }
  }

  handleSearch() {
    var kwd = this.searchword.value;

    this.setState(() => {
      return {
        kwd: kwd,
      };
    });
    //  document.location = "/type?kwd=" + kwd;
    if (kwd === null) kwd = "";
    if (kwd !== "") this.props.history.push("/type?kwd=" + kwd);
  }
}

/*
const Header = (props) => {
    
}*/
const mapStateToProps = (state) => {
  return {
    //focused: state.header.focused,
    //   hotlist: state.header.get('hotlist'),
    //totalpage: state.header.totalpage,
    // page: state.header.page,
    // mouseIn: state.header.mouseIn

    totalpage: state.getIn(["header", "totalpage"]),
    page: state.getIn(["header", "page"]),

    myusername: state.getIn(["gdata", "username"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
    kwd: state.getIn(["type", "kwd"]),

    cpLb: state.getIn(["home", "cpLb"]),
    // adList: state.getIn(["home", "adList"]),
  };
};
const mapDispathToProps = (dispatch) => {
  return {
    changeHomeData(action) {
      dispatch(action);
    },
    changeLoginData(action) {
      dispatch(action);
    },
    handleChangePage(page, totalpage, spin) {
      var originAngle = spin.style.transform.replace(/[^0-9]/gi, "");

      if (!originAngle) originAngle = 0;
      var newangle = originAngle * 1 + 360;
      spin.style.transform = "rotate(" + newangle + "deg)";
      var tmppage = 1;
      if (page < totalpage) tmppage = page + 1;

      const action = actionCreators.changePage(tmppage);
      dispatch(action);
    },
  };
};
export default connect(mapStateToProps, mapDispathToProps)(withRouter(Header));
