import React, { Component, Fragment } from "react";

import { AJAROOT, Dollar } from "@/common/gdata/global.js";

import "@/css/user.css";

class UserTop extends Component {
  render() {
    let {
      gstyle,
      usergrade,
      myusername,
      hb,
      hba,
      xfze,
      totaljj,
      zsxm,
      regtime,
    } = this.props;

    //  if (zsxm === "") zsxm = myusername;

    return (
      <Fragment>
        <div className="row usertop">
          <div
            className="col-xs-3 col-sm-3 col-md-3 col-lg-3"
            style={{ height: "140px", lineHeight: "140px" }}
          >
            {/*  <img src={AJAROOT + gstyle[2]} alt="" className="img-thumbnail" /> */}
          </div>
          <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 left ">
            <div className="row" style={{ lineHeight: "30px" }}>
              <div
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Welcome, {zsxm}
              </div>

              <div>User Account：{myusername}</div>

              <div>User Grade：{usergrade}</div>

              <div>Sign Up Date：{regtime}</div>
            </div>
          </div>
        </div>
        <div
          className="row yue"
          style={{ height: "35px", lineHeight: "35px", paddingLeft: "10px" }}
        >
          <div>
            Consumption：{Dollar}
            {hba}
          </div>

          {/*  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            Consumption：{Dollar}
            {xfze}
          </div> */}
        </div>
      </Fragment>
    );
  }
}

export default UserTop;
