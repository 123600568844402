import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import {
  AJAROOT,
  Dollar,
  rentUnit,
  Color1,
  Color2,
} from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import "@/css/cart.css";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      lists: [],
      totaljine: 0,
      error: "",
    };
  }
  render() {
    const { myusername } = this.props;
    if (myusername === "") {
      return (
        <div className="nologin">
          <Link to="/login">Please Login first. Login now!</Link>
        </div>
      );
    }
    return (
      <div className="container wrap">
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              window.history.back(-1);
            }}
          >
            <span className="iconfont fanhui">&#xe625;</span>
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "22px", fontWeight: "bolder" }}
          >
            Checkout My Shopcart
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            <Link to="/cart" key="cart">
              <span className="icon iconfont" style={{ fontSize: "40px" }}>
                &#xe70b;
              </span>
              <span className="cartnum">{this.state.lists.length}</span>
            </Link>
          </div>
        </div>
        {this.state.lists.map((item, index) => {
          return (
            <div className="row jscart" key={index}>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textcenter">
                <div>
                  <a href={"/detail/" + item["cpid"] + ""}>
                    <img
                      src={AJAROOT + item["imgurl"]}
                      alt=""
                      className="img-thumbnail"
                    ></img>
                  </a>
                </div>
              </div>
              <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 paneltitle ">
                <div className="row textleft">
                  <a href={"/detail/" + item["cpid"] + ""}> {item["title"]}</a>
                </div>

                <div
                  className="row textleft del"
                  style={{ textAlign: "left" }}
                ></div>
                <div className="row textleft">
                  {Dollar}
                  {item["price"]} / {rentUnit}
                </div>
                <div className="row textleft">{item["remark"]}</div>
                <div className="row textleft sl">
                  <button onClick={() => this.changeSl(index, -1)}> - </button>
                  　<button>{item["cpnum"]}</button>　
                  <button onClick={() => this.changeSl(index, 1)}> + </button>
                </div>
              </div>
              <div
                className="col-xs-1 col-sm-1 col-md-1 col-lg-1 close"
                onClick={() => this.delItem(index)}
              >
                X
              </div>
            </div>
          );
        })}
        <div
          className="row"
          style={{
            fontSize: "20px",
            padding: "10px",
            color: Color2,
            fontWeight: "bold",
          }}
        >
          Total: {Dollar}
          {this.state.totaljine}
        </div>
        <div
          className={this.state.error === "" ? "nodis" : "row textcenter error"}
        >
          {this.state.error}
        </div>
        <div className="row textcenter" style={{ paddingTop: "10px" }}>
          <button
            className={this.state.lists.length === 0 ? "nodis" : "cart"}
            style={{ width: "95%" }}
            onClick={() => this.submitCart()}
          >
            <span className="iconfont">&#xe70b;</span>{" "}
            <span className="c1">Next</span>
          </button>
          <span
            className={this.state.lists.length === 0 ? "" : "nodis"}
            style={{ width: "95%", fontSize: "15px" }}
          >
            <a href="/type?typeid=10">
              My shopcart is empty, go to view something!
            </a>
          </span>
        </div>
      </div>
    );
  }

  getData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/cart.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";
    //  console.log(geturl);
    axios.get(geturl).then((res) => {
      //  console.log(res.data);
      const result = res.data;
      const cplist = result["lists"];
      const r = res.data.r;
      if (r !== 0) {
        // this.setState({}); /**/
        //   console.log(result);

        this.setState({
          lists: cplist,
        });

        const action = {
          type: "change_cart_num",
          cartnum: cplist.length,
        };
        this.props.changeCartNum(action);

        this.Jisuan(cplist);
      }
    });
  }
  componentDidMount() {
    // $("#bottomer").css("display", "none");
    const { myusername, ukey } = this.props;

    this.getData(myusername, ukey);
    document.title = "Checkout my shopcart";
  }

  componentWillUnmount() {
    //   $("#bottomer").css("display", "block");
  }

  componentWillUpdate() {}
  componentDidUpdate() {}
  delItem(index) {
    const { myusername, ukey } = this.props;

    let cartlist = this.state.lists;
    let cpid = cartlist[index]["cpid"];

    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?action=delcartcp&delid=" +
      cpid +
      "&u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";

    axios.get(geturl).then((res) => {
      const r = res.data.r;

      if (r === 1) {
        cartlist.splice(index, 1);
        this.setState({
          lists: cartlist,
        });
        this.Jisuan(cartlist);
        const action = {
          type: "change_cart_num",
          cartnum: cartlist.length,
        };
        this.props.changeCartNum(action);
      }
    });
  }
  submitCart() {
    const that = this;
    const { myusername, ukey } = this.props;

    const encodeukey = encodeURIComponent(ukey);

    const postdata = {
      uid: myusername,
      cartdata: this.state.lists,
      gxtx: "0",
    };
    var geturl =
      AJAROOT +
      "/postaja/savecart.aspx?t=add2&u=" +
      myusername +
      "&ukey=" +
      encodeukey +
      "";
    console.log(postdata);
    $.ajax({
      type: "POST",
      dataType: "json",
      url: geturl,
      data: JSON.stringify(postdata),
    })
      .done(function (data) {
        var r = data.r;
        var result = data.s;

        if (r === 1) {
          var cartnum = data.cartnum;
          //  this.postresult = "登陆成功！";
          const action = {
            type: "change_cart_num",
            cartnum: cartnum,
          };
          that.props.changeCartNum(action);
          that.props.history.push("/cart2");
        } else {
          that.setState({
            error: result,
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
  changeSl(index, add) {
    let cartlist = this.state.lists;
    let sl = cartlist[index]["cpnum"];
    const amount = cartlist[index]["amount"];
    sl = sl * 1 + add * 1;
    if (sl <= 0) sl = 0;
    if (sl > amount * 1) sl = amount * 1;
    cartlist[index]["cpnum"] = sl;
    this.setState({
      lists: cartlist,
    });
    this.Jisuan(cartlist);
  }
  Jisuan(cartlist) {
    //  let cartlist = this.state.lists;
    let totaljine = 0;
    for (var g = 0; g < cartlist.length; g++) {
      var price = cartlist[g].price;

      var cpnum = cartlist[g].cpnum;
      totaljine += price * cpnum;
    }
    this.setState({
      totaljine: totaljine,
    });
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};
const mapDispatch = (dispath) => ({
  changeCartNum(action) {
    dispath(action);
  },
});
export default connect(mapStateToProps, mapDispatch)(Cart);
