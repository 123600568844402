import * as actionTypes from "./actionTypes";
import axios from "axios";
import { AJAROOT } from "@/common/gdata/global.js";

export const searchFocus = () => ({
  type: actionTypes.SEARCH_FOCUS,
});

export const searchBlur = () => ({
  type: actionTypes.SEARCH_BLUR,
});

export const mouseEnter = () => ({
  type: actionTypes.MOUSE_ENTER,
});

export const mouseLeave = () => ({
  type: actionTypes.MOUSE_LEAVE,
});

export const changePage = (page) => ({
  type: actionTypes.CHANGE_PAGE,
  page: page,
});

export const getHotList = (myusername, ukey) => {
  const geturl =
    AJAROOT +
    "/getaja/xx.aspx?action=kwdsch&u=" +
    myusername +
    "&ukey=" +
    encodeURIComponent(ukey) +
    "";

  return (dispatch) => {
    axios
      .get(geturl)
      .then((res) => {
        const data = res.data;

        const totalpage = Math.ceil(data["kwds"].length / 10);

        const action = {
          type: actionTypes.CHANGE_HOT_LIST,
          data: data["kwds"],
          totalpage: totalpage,
          style: data["style"],

          usertype: data["usertype"],
          usergrade: data["usergrade"],
          hb: data["hb"],
        };
        dispatch(action);
        if (myusername !== "") {
          const lgstatus = data["lgstatus"];

          if (lgstatus === "0") {
            const action2 = {
              type: "change_login_data",
              username: "",
              ukey: "",
              usertype: 0,
              usergrade: 0,
              cartnum: 0,
            };
            dispatch(action2);
          }
        }
      })
      .catch((e) => {
        console.log("error" + e);
      });
  };
};
