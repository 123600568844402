import React, { useState } from "react";

import axios from "axios";

import { AJAROOT } from "@/common/gdata/global.js";

import "@/css/home.css";

import { Link } from "react-router-dom";
var test = "aaa";
var count2 = 0;
function Hblist(props) {
  const [count, setCount] = useState(0);
  count2 = count;
  //getData();
  return (
    <>
      <p>{count}</p>
    </>
  );
}
function getData() {
  test = "bbb";
}
export default Hblist;
