import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { withRouter, Link } from "react-router-dom";

import { LoginWrapper, LoginBox, Input, Button, PostResult } from "./style";
import { getRndChr } from "@/jscript/common.js";
import { AJAROOT } from "@/common/gdata/global.js";
import "@/css/global.css";
class Findpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postresult: "",
      posterror: "",
    };
  }
  render() {
    // const { postresult } = this.props;
    //  console.log("render");
    return (
      <LoginWrapper>
        <LoginBox style={{ height: "240px" }}>
          <div
            style={{
              paddingRight: "15px",
              textAlign: "right",
              fontSize: "22px",
            }}
          >
            <Link to="/">X</Link>
          </div>
          <Input
            placeholder="Your Email"
            ref={(input) => {
              this.username = input;
            }}
          />

          <div className="textcenter">
            <a href="/login">I got my password, Login now!</a>
          </div>
          <Button onClick={() => this.login2()}>Request new password</Button>

          {/*  <Button onClick={this.login2.bind(this)}>登陆</Button> */}
          <PostResult id="result2">
            <span className={this.state.posterror === "" ? "" : "nodis"}>
              {this.state.postresult}
            </span>
            <span className="loginerror">{this.state.posterror}</span>
          </PostResult>
        </LoginBox>
      </LoginWrapper>
    );
  }
  componentDidMount() {}
  login2() {
    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Checking...",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    var username = this.username.value;

    const this2 = this;
    var error = "";
    if (username === "") {
      error = "Please fill in email";
    }
    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        posterror: error,
      });
    } else {
      $.ajax({
        type: "GET",
        dataType: "json",
        url: AJAROOT + "/getaja/xx.aspx?action=findpass&email=" + username + "",
      })
        .done(function (data) {
          var r = data.r;
          var msg = data.s;
          console.log(data);
          if (r === "1") {
            //  this.postresult = "登陆成功！";

            this2.setState({
              posterror: "",
              postresult:
                "A reset email sent to your email, please click the link of it to reset new password.",
            });
            this2.props.history.push(
              "/alert?message=A reset email sent to your email, please click the link of it to reset new password.&tourl=&urltitle=Home"
            );
            // this2.props.history.push("/");
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              posterror: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }
}

export default Findpass;
