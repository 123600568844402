import React, { Component } from "react";

import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import {
  AJAROOT,
  Dollar,
  rentUnit,
  Color2,
  Tax,
  insuranceHl,
  PickUpDiff,
  PriceBl1,
  PriceBl2,
} from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import "@/css/cart.css";
import { mileFee } from "../../common/gdata/global";

class Cart2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      /*   province: "",
      city: "",
      country: "",
      zsxm: "",
      mobile: "",
      address: "",
      first: "1", */
      discount: 0,
      hba: 0,
      hbb: 0,
      lists: [],
      addlist: [],
      totaljine: 0,
      sftotaljine: 0,
      shipfee: 0,
      tax: 1,
      aftertax: 0,
      distance: 0,
      insuranceHl: 0,

      newaddressshow: true,
      fkfs: "2",
      addid: "",
      selfth: 1,
      selfgh: 1,
      insurance: 0,
      pickupday: "",
      period: "",
      periodPriceBl: 1,
      error: "",
      subaddresserror: "",
      subaddressok: "",
      showins: 0,
      needship: 0,
      oneway: 0,
    };
  }
  render() {
    const { myusername, hb } = this.props;
    if (myusername === "") {
      return (
        <div className="nologin">
          <Link to="/login">Please Login first. Login now!</Link>
        </div>
      );
    }

    var date = new Date();

    // 对日期加减：

    var arrpickupday = [];
    for (var i = 0; i < PickUpDiff; i++) {
      var date2 = new Date();
      var dd = new Date(date2.setDate(date.getDate() + i)).toLocaleDateString();
      // console.log(dd);
      arrpickupday.push(dd);
    }

    return (
      <div className="container wrap">
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              window.history.back(-1);
            }}
          >
            <span className="iconfont fanhui">&#xe625;</span>
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "22px", fontWeight: "bolder" }}
          >
            Checkout shipment and periodment
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
            {/* <Link to="/cart" key="cart">
              <span className="icon iconfont" style={{ fontSize: "40px" }}>
                &#xe70b;
              </span>
              <span className="cartnum">{this.state.lists.length}</span>
            </Link> */}
          </div>
        </div>
        {this.state.lists.map((item, index) => {
          return (
            <div className="row jscart" key={index}>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 textcenter">
                <div>
                  <a href={"/detail/" + item["cpid"] + ""}>
                    <img
                      src={AJAROOT + item["imgurl"]}
                      alt=""
                      className="img-thumbnail"
                    ></img>
                  </a>
                </div>
              </div>
              <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 paneltitle">
                <div className="row textleft">
                  <a href={"/detail/" + item["cpid"] + ""}>{item["title"]}</a>
                </div>

                <div
                  className="row textleft del"
                  style={{ textAlign: "left" }}
                ></div>
                <div className="row textleft">
                  {Dollar}
                  {item["price"]} / {rentUnit}
                </div>
                <div className="row textleft">{item["remark"]}</div>
                <div className="row textleft sl">X 　{item["cpnum"]}</div>
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 close"></div>
            </div>
          );
        })}
        <div
          className="row"
          style={{
            fontSize: "20px",
            padding: "10px",
            color: "#999",
            fontWeight: "bold",
            fontSize: "16px",
            lineHeight: "26px",
          }}
        >
          <div style={{}}>
            + Order Amount: {Dollar}
            {(this.state.totaljine * this.state.periodPriceBl).toFixed(2)}
          </div>
          <div style={{}}>
            - Discount: {Dollar}
            {(
              this.state.totaljine *
              this.state.periodPriceBl *
              this.state.discount
            ).toFixed(2)}
            ({this.state.discount * 100}%)
          </div>
          <div style={{}}>
            + Insurance Fee: {Dollar}
            {this.getInsurance()}
          </div>
          <div style={{}}>
            + Ship Fee: {Dollar}
            {this.jsyunfei()}
            <span className={this.state.distance === 0 ? "nodis" : ""}>
              (Distance {this.state.distance}km)
            </span>
            <span className={this.state.distance === 0 ? "" : "nodis"}>
              (invalid zip/postal)
            </span>
          </div>

          <div className={this.state.tax === 0 ? "nodis" : ""} style={{}}>
            + Tax Fee: {Dollar}
            {this.Taxjine()}
          </div>
          <div style={{ color: Color2 }}>
            = Total: {Dollar}
            {this.AfterTaxJine()}
          </div>
        </div>
        <div className="row" style={{ padding: "10px" }}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
            <div className="row">
              <span style={{ fontWeight: "bold" }}>Delivery Method:</span>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <input
                  type="radio"
                  checked={1 === this.state.needship}
                  onChange={() => this.changeShip(1)}
                  onClick={() => this.changeShip2()}
                  style={{ width: "18px", height: "18px" }}
                />{" "}
                Ship
              </div>
            </div>
            <div className={this.state.needship === 1 ? "row" : "nodis"}>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                style={{ paddingLeft: "30px" }}
              >
                <input
                  type="radio"
                  checked={1 === this.state.oneway}
                  onChange={() => this.seltwoway(1)}
                  onClick={() => this.seltwoway2()}
                  style={{ width: "18px", height: "18px" }}
                />{" "}
                one-way ship
              </div>

              <div
                className={this.state.oneway === 1 ? "row" : "nodis"}
                style={{ paddingLeft: "50px" }}
              >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                  <input
                    checked={1 === this.state.selfth}
                    type="radio"
                    name="selfth"
                    onChange={() => this.setSelfTh()}
                    onClick={() => this.setSelfTh2()}
                    style={{ width: "18px", height: "18px" }}
                  />{" "}
                  Pick-up myself
                </div>
              </div>
              <div
                className={this.state.oneway === 1 ? "row" : "nodis"}
                style={{ paddingLeft: "50px" }}
              >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                  <input
                    type="radio"
                    name="selfgh"
                    checked={1 === this.state.selfgh}
                    onChange={() => this.setSelfGh()}
                    onClick={() => this.setSelfGh2()}
                    style={{ width: "18px", height: "18px" }}
                  />{" "}
                  Return myself
                </div>
              </div>
            </div>

            <div className={this.state.needship === 1 ? "row" : "nodis"}>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                style={{ paddingLeft: "30px" }}
              >
                <input
                  type="radio"
                  checked={2 === this.state.oneway}
                  onChange={() => this.seltwoway(2)}
                  onClick={() => this.seltwoway2()}
                  style={{ width: "18px", height: "18px" }}
                />{" "}
                two-way ship
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <input
                  type="radio"
                  checked={0 === this.state.needship}
                  onChange={() => this.changeShip(0)}
                  onClick={() => this.changeShip2()}
                  style={{ width: "18px", height: "18px" }}
                />{" "}
                Pick up
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <input
                  type="checkbox"
                  name="insurance"
                  onChange={() => this.setInsurance()}
                  style={{ width: "18px", height: "18px" }}
                />{" "}
                Insurance ({insuranceHl * 100}%)
                <span
                  style={{
                    color: "#222",
                    fontSize: "18px",
                    paddingLeft: "10px",
                  }}
                  onClick={() => this.showIns()}
                >
                  ?
                </span>
              </div>
            </div>
            <div className={this.state.showins === 0 ? "nodis" : "aboutins"}>
              <div
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  fontSize: "20px",
                }}
                onClick={() => this.showIns()}
              >
                X
              </div>
              <div>what's this?</div>
              <div>1.******</div>
              <div>2.gggg</div>
            </div>
            <div className="row" style={{ paddingTop: "10px" }}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <select
                  id="pickupday"
                  onChange={() => this.setPickUpDay()}
                  style={{ width: "180px", height: "30px" }}
                >
                  <option value="">Select pick up day</option>
                  {arrpickupday.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>{" "}
                <span style={{ color: "#ff0000" }}>*</span>
              </div>
            </div>
            <div className="row" style={{ paddingTop: "10px" }}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <select
                  id="period"
                  onChange={() => this.setPeriod()}
                  style={{ width: "180px", height: "30px" }}
                >
                  <option value="">Select rental term</option>
                  <option value="1">1 month rental</option>
                  <option value="2">45 days rental</option>
                  <option value="3">2 months rental</option>
                </select>{" "}
                <span style={{ color: "#ff0000" }}>*</span>
              </div>
            </div>
            {/* <div className="row" style={{ paddingTop: "10px" }}>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <select
                  id="period"
                  placeholder="Select rent period"
                  onChange={() => this.setPeriod()}
                  style={{ width: "180px", height: "30px" }}
                >
                  <option value="">Select rent period</option>
                  <option value="">One month</option>
                  <option value="">45 days</option>
                  <option value="">Two months</option>
                </select>{" "}
              </div>
            </div> */}
          </div>
        </div>
        <div
          className="row"
          style={{
            padding: "10px",
            display:
              this.state.selfgh === 1 && this.state.selfth === 1
                ? "none"
                : "block",
          }}
        >
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
            <div className="row">
              <span style={{ fontWeight: "bold" }}>Shipping Address</span>
            </div>

            {/*  {this.state.addlist.map((item, index) => {
              var province = item["province"];
              var city = item["city"];
              var country = item["country"];
              //   var diqu = "";
              // if (country !== "") diqu = province + "-" + city + "-" + country;
              //  else if (city !== "") diqu = province + "-" + city;
              var address = item["address"];
              var addressstr = "";
              // if (address !== "") {
              //   if (diqu !== "") address = diqu + ", " + address;
              //  }

              var firstname = item["firstname"];
              var lastname = item["lastname"];
              var mobile = item["mobile"];
              var zip = item["zip"];
              var zsxm = lastname;
              if (firstname !== "") {
                zsxm = firstname + " " + lastname;
              }
              addressstr = zsxm;

              if (mobile !== "") {
                addressstr += ", Tel:" + mobile;
              }
              if (address !== "") {
                addressstr += ", " + address;
              }
              if (city !== "") {
                addressstr += ", " + city;
              }
              if (province !== "") {
                addressstr += " " + province;
              }
              if (zip !== "") {
                addressstr = addressstr + " " + zip;
              }
              return (
                <div className="row" key={index}>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                    <input
                      type="radio"
                      name="radaddid"
                      checked={item["id"] === this.state.addid}
                      onChange={() => this.selAddress(index)}
                      onClick={() => this.selAddress2(index)}
                      style={{ width: "18px", height: "18px" }}
                    />{" "}
                    {addressstr}
                    <span
                      style={{
                        paddingLeft: "15px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => this.delAddress(index, item["id"])}
                    >
                      【Delete】
                    </span>
                  </div>
                </div>
              );
            })} */}
          </div>

          {/* <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <button onClick={() => this.showNewaddress(true)}>
                Set Address
              </button>
            </div>
          </div> */}
          <div
            className={
              this.state.newaddressshow === false
                ? "row newaddress nodis"
                : "row newaddress"
            }
          >
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row nodis">
                <div
                  className="col-xs-11 col-sm-11 col-md-11 col-lg-11 "
                  style={{ padding: "0px" }}
                >
                  {/*  <span>First Name:</span> */}
                  <input
                    placeholder="Your First Name"
                    id="firstname"
                    /*  ref={(input) => {
                    this.firstname = input;
                  }} */
                  />{" "}
                  *
                </div>
                <div
                  className="col-xs-1 col-sm-1 col-md-1 col-lg-1"
                  onClick={() => this.showNewaddress(false)}
                >
                  　
                </div>
              </div>
              {/* <div className="row">　　地区：</div> */}
              <div className="row nodis">
                {/* <span>Last Name:</span> */}
                <input
                  placeholder="Your Last Name"
                  id="lastname"
                  /* ref={(input) => {
                  this.lastname = input;
                }} */
                />{" "}
                *
              </div>
              <div className="row">
                {/*  <span>Street Address:</span> */}
                <input
                  placeholder="Your Address"
                  style={{ width: "300px" }}
                  id="address"
                  /* ref={(input) => {
                  this.address = input;
                }} */
                />{" "}
                *
              </div>
              <div className="row">
                {/*  <span>Street Address:</span> */}
                <input
                  placeholder="Apartment suite, etc. (optional)"
                  style={{ width: "300px" }}
                  id="apartment"
                  /* ref={(input) => {
                  this.address = input;
                }} */
                />{" "}
              </div>
              <div className="row">
                {/*  <span>City:</span> */}
                <input
                  placeholder="Your City"
                  id="city"
                  /* ref={(input) => {
                  this.city = input;
                }} */
                />{" "}
                *
              </div>
              <div className="row">
                {/*  <span>State/Province:</span> */}
                <input
                  placeholder="Your State/Province"
                  id="province"
                  name="province"
                  /* ref={(input) => {
                  this.province = input;
                }} */
                />{" "}
                *
              </div>
              <div className="row">
                {/*  <span>Zip/Postal Code:</span> */}
                <input
                  id="zip"
                  placeholder="Your Zip/Postal Code"
                  /* ref={(input) => {
                  this.zip = input;
                }} */
                />{" "}
                *
              </div>
              <div className="row">
                {/*  <span>Telephone:</span> */}
                <input
                  placeholder="Your Telephone"
                  id="mobile"
                  /* ref={(input) => {
                  this.mobile = input;
                }} */
                />{" "}
                *
              </div>
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <button
                    name="submit"
                    style={{
                      width: "50%",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                    onClick={() => this.submitAddress()}
                  >
                    <span
                      className="c1"
                      style={{
                        float: "center",
                        width: "100%",
                        display: "inline",
                      }}
                    >
                      Save Address
                    </span>
                  </button>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <span
                    style={{
                      textAlign: "left",
                      width: "100%",
                    }}
                    className={
                      this.state.subaddresserror === ""
                        ? "nodis"
                        : "textcenter error"
                    }
                  >
                    {this.state.subaddresserror}
                  </span>
                  <span
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontSize: "25px",
                    }}
                    className={
                      this.state.subaddressok === "" ? "nodis" : "textcenter"
                    }
                  >
                    {this.state.subaddressok}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: "10px" }}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
            <div className="row">
              <span style={{ fontWeight: "bold" }}>Payment：</span>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <input
                  type="radio"
                  name="fkfs"
                  checked
                  onChange={() => this.selFkfs("2")}
                  style={{ width: "18px", height: "18px" }}
                />{" "}
                Offline pay (We'll contact you)
                {/*  {hb["A"]}: [balance {Dollar}
            {this.state.hba}] */}
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.error === "" ? "nodis" : "row textcenter error"}
        >
          {this.state.error}
        </div>
        <div className="row textcenter" style={{ paddingTop: "10px" }}>
          <button
            className="cart"
            style={{ width: "95%" }}
            onClick={() => this.submitCart()}
          >
            <span className="iconfont">&#xe70b;</span>{" "}
            <span className="c1">Place order</span>
          </button>
        </div>
      </div>
    );
  }
  showIns() {
    let value = this.state.showins;
    // if (value == 1) value = value % 2;
    value = (value + 1) % 2;

    this.setState({
      showins: value,
    });
  }
  seltwoway(val) {
    if (val === 1) {
      this.setState({
        selfth: 1,
      });
    } else if (val === 2) {
      this.setState({
        selfth: 0,
        selfgh: 0,
      });
    }
    this.setState({
      oneway: val,
    });
  }
  seltwoway2(index) {}
  /* selAddress(index) {
    let addlist = this.state.addlist;
    const addid = addlist[index]["id"];
    if (addid === this.state.addid) return;
    let zip = addlist[index]["zip"];
    for (var g = 0; g < addlist.length; g++) {
      addlist[g]["checked"] = 0;
    }
    addlist[index]["checked"] = 1;

    const { myusername, ukey } = this.props;

    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=getdistance&zip1=&zip2=" +
      zip +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;
      // this.setState({}); 

      const r = result.r;

      if (r === 1) {
        let distance = result.distance * 1;

        let shipfee = distance * mileFee * 2;
        shipfee = shipfee.toFixed(2);

        this.setState({
          addlist: addlist,
          addid: addid,
          shipfee: shipfee,
          distance: distance,
          zip: zip,
          error: "",
        });
      }
    });
  } */
  setPickUpDay() {
    var pickupday = document.getElementById("pickupday").value;

    this.setState({
      pickupday: pickupday,
      error: "",
    });
  }
  setPeriod() {
    var period = document.getElementById("period").value;

    var pricebl = 1;
    if (period === "2") pricebl = PriceBl1;
    else if (period === "3") pricebl = PriceBl2;
    this.setState({
      period: period,
      periodPriceBl: pricebl,
      error: "",
    });
  }

  showNewaddress(value) {
    this.setState({
      newaddressshow: value,
      error: "",
    });
  }
  getInsurance() {
    return (
      this.state.insuranceHl *
      this.state.totaljine *
      (1 - this.state.discount * 1) *
      this.state.periodPriceBl *
      1
    ).toFixed(2);
  }
  setInsurance() {
    let value = this.state.insurance;
    // if (value == 1) value = value % 2;
    let insuranceHl2 = insuranceHl;
    value = (value + 1) % 2;
    if (value === 0) insuranceHl2 = 0;
    /* if (value == 1)
      insuranceFee =
        insuranceHl *
        this.state.totaljine *
        (1 - this.state.discount * 1) *
        this.state.periodPriceBl *
        1;

    insuranceFee = insuranceFee.toFixed(2); */
    this.setState({
      insurance: value,
      insuranceHl: insuranceHl2,
    });
  }
  changeShip2() {}
  changeShip(val) {
    //  let value = this.state.needship;
    // if (value == 1) value = value % 2;
    //  value = (value + 1) % 2;
    let value = val;
    var selfgh = 0;
    var selfth = 0;
    if (value == 0) {
      selfgh = 1;
      selfth = 1;
      this.setState({
        shipfee: 0,
      });
    } else {
      this.setState({
        oneway: 2,
      });
    }
    this.setState({
      needship: value,
      selfth: selfth,
      selfgh: selfgh,
    });
  }
  setSelfTh() {
    let value = this.state.selfth;
    // if (value == 1) value = value % 2;
    value = (value + 1) % 2;

    if (value == 1) {
      this.setState({
        selfgh: 0,
        selfth: 1,
      });
    } else
      this.setState({
        selfgh: 1,
        selfth: 0,
      });

    // this.jsyunfei(value, value2);
  }
  jsyunfei() {
    let shipfee = 0;
    let singlefee = 0; // this.state.distance * mileFee;
    if (this.state.distance * 1 <= 20) singlefee = 118;
    else if (this.state.distance * 1 > 20 && this.state.distance * 1 <= 40)
      singlefee = 148;
    else if (this.state.distance * 1 > 40 && this.state.distance * 1 <= 70)
      singlefee = 168;
    else if (this.state.distance * 1 > 70 && this.state.distance * 1 <= 100)
      singlefee = 228;
    else if (this.state.distance * 1 > 100) singlefee = 298;
    //   singlefee = singlefee.toFixed(2);
    if (this.state.selfth == 0) {
      shipfee += singlefee * 1;
    }
    if (this.state.selfgh == 0) {
      shipfee += singlefee * 1;
    }

    if (shipfee < 0) shipfee = 0;

    return shipfee;
    // console.log(shipfee);
    //
  }
  setSelfTh2() {}
  setSelfGh2() {}
  setSelfGh() {
    let value = this.state.selfgh;
    value = (value + 1) % 2;
    if (value == 1) {
      this.setState({
        selfth: 0,
        selfgh: 1,
      });
    } else
      this.setState({
        selfth: 1,
        selfgh: 0,
      });
    var value2 = (value + 1) % 2;
    //this.jsyunfei(value, value2);
  }
  selFkfs(fkfs) {
    this.setState({
      fkfs: fkfs,
      error: "",
    });
  }
  /* delAddress(index, addid) {
    const { myusername, ukey } = this.props;

    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "&action=deladdress&delid=" +
      addid +
      "";

    axios
      .get(geturl)
      .then((res) => {
        const result = res.data;
      

        const r = result.r;
        const s = result.s;
        if (r === "1") {
          let addlist = this.state.addlist;
          addlist.splice(index, 1);
          if (this.state.addid === addid) {
            this.setState({
              addid: "",
              shipfee: 0,
              distance: 0,
              zip: "",
              addlist: addlist,
            });
          } else {
            this.setState({
              addlist: addlist,
            });
          }
        } else {
          this.setState({
            error: s,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {});
  } */
  getData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/cart.aspx?u=" +
      myusername +
      "&ukey=" +
      encodeURIComponent(ukey) +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      const r = result.r;
      if (r != 0) {
        //  console.log(result);
        // this.setState({}); /**/
        // console.log(result);
        // const addlist = result["addlist"];
        let hba = result["hba"];
        let discount = result["discount"];
        let tax = result["tax"] * 1;

        let addid = result["seladdid"];
        // let zip = result["zip"];
        let distance = result["distance"];
        let shipfee = distance * mileFee * 2;
        shipfee = shipfee.toFixed(2);
        let firstname = result["firstname"];
        let lastname = result["lastname"];
        let mobile = result["mobile"];
        let address = result["address"];
        let city = result["city"];
        let province = result["province"];
        let zip = result["zip"];
        // let mobile = result["mobile"];
        /*  if (addlist.length >= 1) addid = addlist[0]["id"];

      console.log(result);
      for (var g = 0; g < addlist.length; g++) {
        if ((addlist[g]["checked"] = 1)) {
          addid = addlist[g]["id"];

          break;
        }
      } */
        this.setState({
          lists: result["lists"],
          //  addlist: addlist,
          addid: addid,
          hba: hba,
          discount: discount,
          tax: tax,
          distance: distance,
          shipfee: shipfee,
          /*  firstname: firstname,
          lastname: lastname,
          mobile: mobile,
          address: address,
          city: city,
          province: province,
          zip: zip, */
        });
        // document.getElementById("firstname").value = firstname;
        // document.getElementById("lastname").value = lastname;
        document.getElementById("mobile").value = mobile;
        document.getElementById("address").value = address;
        document.getElementById("city").value = city;

        document.getElementById("zip").value = zip;
        this.Jisuan(result["lists"]);
      }
    });
  }
  componentDidMount() {
    // console.log((this.state.selfgh + 1) % 2);
    //$("#bottomer").css("display", "none");
    try {
      document.getElementById("province").value = "Ontario";
    } catch {}
    const { myusername, ukey } = this.props;

    this.getData(myusername, ukey);
    document.title = "Checkout shipment and periodment";
  }

  componentWillUnmount() {
    // $("#bottomer").css("display", "block");

    this.setState = (state, callback) => {
      return;
    };
  }

  submitCart() {
    const that = this;
    const { myusername, ukey } = this.props;

    const encodeukey = encodeURIComponent(ukey);
    var addid = this.state.addid;

    var cando = true;
    var error = "";

    if (addid === "" && (this.state.selfgh === 0 || this.state.selfth === 0)) {
      cando = false;
      error = "Please set address！";
    } else if (this.state.fkfs === "") {
      cando = false;
      error = "Please select paymethod！";
    } else if (this.state.pickupday === "") {
      cando = false;
      error = "Please select pickup day";
    } else if (this.state.period === "") {
      cando = false;
      error = "Please select rental term";
    }
    if (!cando) {
      this.setState({
        error: error,
      });
    } else {
      const postdata = {
        uid: myusername,
        czu: "",
        nickname: "",
        txurl: "",
        remark: "",
        openid: "",
        addid: addid,
        fkfs: this.state.fkfs,
        insurance: this.state.insurance,
        selfth: this.state.selfth,
        selfgh: this.state.selfgh,
        pickupday: this.state.pickupday,
        gxtx: "0",
        tax: this.state.tax,
        shipfee: this.state.shipfee,
        period: this.state.period,
      };
      var geturl =
        AJAROOT +
        "/postaja/suborder.aspx?t=add2&u=" +
        myusername +
        "&ukey=" +
        encodeukey +
        "";

      $.ajax({
        type: "POST",
        dataType: "json",
        url: geturl,
        data: JSON.stringify(postdata),
      })
        .done(function (data) {
          var r = data.r;
          var result = data.s;

          if (r === "1") {
            //  this.postresult = "登陆成功！";
            const action = {
              type: "change_cart_num",
              cartnum: 0,
            };
            that.props.changeCartNum(action);
            that.props.history.push(
              "/alert?message=Success!&tourl=user/myorder/9&urltitle=Return To My Orders!"
            );
          } else {
            that.setState({
              error: result,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  submitAddress() {
    const that = this;
    var cando = true;
    var error = "";
    // const firstname = document.getElementById("firstname").value;
    // const lastname = document.getElementById("lastname").value;
    const mobile = document.getElementById("mobile").value;
    const address = document.getElementById("address").value;
    const zip = document.getElementById("zip").value;
    const province = document.getElementById("province").value;
    const city = document.getElementById("city").value;
    const apartment = document.getElementById("apartment").value;
    const country = "";

    if (zip === "") {
      cando = false;
      error = "Please fill in zip!";
    }
    if (province === "") {
      cando = false;
      error = "Please fill in province!";
    }
    if (city === "") {
      cando = false;
      error = "Please fill in city!";
    }
    if (mobile === "") {
      cando = false;
      error = "Please fill in telephone!";
    }

    if (address === "") {
      cando = false;
      error = "Please fill in street address!";
    }
    /*  if (lastname === "") {
      cando = false;
      error = "Please fill in last name!";
    } */

    if (!cando) {
      this.setState({
        subaddresserror: error,
        subaddressok: "",
        error: "",
      });
    } else {
      const { myusername, ukey } = this.props;

      const encodeukey = encodeURIComponent(ukey);

      const postdata = {
        uid: myusername,
        province: province, //this.state.province,
        city: city, //this.state.city,
        country: country, //this.state.country,
        firstname: "",
        lastname: "",
        mobile: mobile,
        zip: zip,
        address: address,
        apartment: apartment,
        first: "1",
      };
      var geturl =
        AJAROOT +
        "/postaja/savexx.aspx?action=addaddress&u=" +
        myusername +
        "&ukey=" +
        encodeukey +
        "";

      $.ajax({
        type: "POST",
        dataType: "json",
        url: geturl,
        data: JSON.stringify(postdata),
      })
        .done(function (data) {
          // console.log(data);
          var r = data.r;
          var result = data.s;
          var distance = data.distance;
          if (r === "1") {
            let shipfee = distance * mileFee * 2;
            shipfee = shipfee.toFixed(2);

            // that.showNewaddress(false);
            var id = data.id;
            //  this.postresult = "登陆成功！";
            /*   const newaddress = {
              id: id,
              address: address,
              checked: 1,
              city: city,
              country: country,

              mobile: mobile,
              od: 0,
              province: province,
              tel: "",
              firstname: firstname,
              lastname: lastname,
              zip: zip,
              //  cartnum: cartnum,
            };
            let addlist = that.state.addlist;

            for (var g = 0; g < addlist.length; g++) {
              addlist[g]["checked"] = 0;
            }
            addlist[addlist.length] = newaddress; */
            that.setState({
              //  addlist: addlist,
              addid: id,
              zip: zip,
              shipfee: shipfee,
              distance: distance,
              subaddressok: result,
              subaddresserror: "",
              error: "",
            });
            //  that.props.changeCartNum(action);
            // this.props.push("/cart2");
          } else {
            that.setState({
              subaddresserror: result,
              subaddressok: "",
              error: "",
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }

  Jisuan(cartlist) {
    //  let cartlist = this.state.lists;

    let totaljine = 0;
    for (var g = 0; g < cartlist.length; g++) {
      var price = cartlist[g].price;

      var cpnum = cartlist[g].cpnum;
      totaljine += price * cpnum;
    }

    this.setState({
      totaljine: totaljine,
    });
  }
  Taxjine() {
    let shipfee = this.jsyunfei();
    let koushuijine = 0;
    let insurancefee = this.getInsurance() * 1;

    let zk =
      this.state.periodPriceBl *
      this.state.totaljine *
      this.state.discount.toFixed(2);

    koushuijine =
      this.state.periodPriceBl * this.state.totaljine -
      zk +
      shipfee * 1 +
      insurancefee;

    koushuijine = koushuijine * Tax * this.state.tax * 1;

    koushuijine = koushuijine.toFixed(2);

    return koushuijine;
  }
  AfterTaxJine() {
    let shipfee = this.jsyunfei();
    let aftertax = 0;
    // console.log("totaljine" + totaljine);
    let insurancefee = this.getInsurance() * 1;
    let koushuijine =
      this.state.periodPriceBl *
        this.state.totaljine *
        (1 - this.state.discount) +
      shipfee * 1 +
      insurancefee;

    aftertax = koushuijine * 1 + this.Taxjine() * 1;
    aftertax = aftertax.toFixed(2);
    return aftertax;
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
    hb: state.getIn(["header", "hb"]),
  };
};
const mapDispatch = (dispath) => ({
  changeCartNum(action) {
    dispath(action);
  },
});
export default connect(mapStateToProps, mapDispatch)(Cart2);
