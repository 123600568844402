//import { combineReducers } from 'redux';
import { combineReducers } from "redux-immutable";
import headerReducer from "../common/header/store/reducer";
import gdataReducer from "../common/gdata/store/reducer";
import homeReducer from "../pages/home/store/reducer";
//import loginReducer from "../pages/login/store/reducer";
import typeReducer from "../pages/type/store/reducer";

/*
export default combineReducers({
    header: headerReducer
})*/

const reducer = combineReducers({
  header: headerReducer,
  home: homeReducer,
  type: typeReducer,
  gdata: gdataReducer,
  // login: loginReducer,
});

export default reducer;
