import * as actionTypes from "./actionTypes";
import { fromJS } from "immutable";

const defaultState = fromJS({
  page: 1,
  totalpage: 1,
  style: {},
  hb: {},
  usertype: {},
  usergrade: {
    1: "Grade 1",
    2: "Grade 2",
    3: "Grade 3",
    4: "Grade 4",
    5: "Grade 5",
    6: "Grade 6",
  },
});
/*
const defaultState =
{
    focused: false,
    mouseIn: false,
    hotlist: [],
    page: 1,
    totalpage: 1
};
*/
const todo = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_FOCUS: {
      // const newState = JSON.parse(JSON.stringify(state));
      //newState.focused = true;
      // return newState;
      return state.set("focused", true);
    }
    case actionTypes.SEARCH_BLUR: {
      return state.set("focused", false);
    }
    /* case actionTypes.CHANGE_HOT_LIST: {
      return state.merge({
        hotlist: fromJS(action.data),
        totalpage: action.totalpage,
        ukey: action.ukey,
        usergrade: action.usergrade,
        usertype: action.usertype,
        style: action.style,
        hb: action.hb,
      });
    } */
    case actionTypes.MOUSE_ENTER: {
      return state.set("mouseIn", true);
    }
    case actionTypes.MOUSE_LEAVE: {
      return state.set("mouseIn", false);
    }
    case actionTypes.CHANGE_PAGE: {
      return state.set("page", action.page);
      //  return state.set('hotlist',action.page);
    }
    default: {
      return state;
    }
  }
};
export default todo;
