import React, { Component } from "react";

import $ from "jquery";
import queryString from "query-string";
import { withRouter, Link } from "react-router-dom";
import { LoginWrapper, LoginBox, Input, Button, PostResult } from "./style";

import { AJAROOT } from "@/common/gdata/global.js";
import "@/css/global.css";
class Resetpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      postresult: "",
      posterror: "",
    };
  }
  render() {
    // const { postresult } = this.props;
    //  console.log("render");
    return (
      <LoginWrapper>
        <LoginBox style={{ height: "250px" }}>
          <div
            style={{
              paddingRight: "15px",
              textAlign: "right",
              fontSize: "22px",
            }}
          >
            <Link to="/">X</Link>
          </div>
          <Input
            type="password"
            placeholder="Your new password"
            ref={(input) => {
              this.password = input;
            }}
          />
          <Input
            type="password"
            placeholder="Retype your new password"
            ref={(input) => {
              this.password2 = input;
            }}
          />
          <div className="textcenter">
            <a href="/login">I got my password, Login now!</a>
          </div>
          <Button onClick={() => this.login2()}>Request new password</Button>

          {/*  <Button onClick={this.login2.bind(this)}>登陆</Button> */}
          <PostResult id="result2">
            <span className={this.state.posterror === "" ? "" : "nodis"}>
              {this.state.postresult}
            </span>
            <span className="loginerror">{this.state.posterror}</span>
          </PostResult>
        </LoginBox>
      </LoginWrapper>
    );
  }
  componentDidMount() {
    var { token } = queryString.parse(this.props.location.search);

    if (token == null) token = "";

    this.setState({
      token: token,
    });
  }
  login2() {
    // document.getElementById("result2").style.color = "#000000";

    this.setState({
      postresult: "Checking...",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    var password = this.password.value;
    var password2 = this.password2.value;

    const this2 = this;
    var error = "";
    if (password === "") {
      error = "Please fill in password";
    } else if (password !== password2) {
      error = "Retype password doesn't math";
    }
    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        posterror: error,
      });
    } else {
      let url =
        AJAROOT +
        "/getaja/xx.aspx?action=resetpass&token=" +
        this.state.token +
        "&password=" +
        password +
        "";
      console.log(url);
      $.ajax({
        type: "GET",
        dataType: "json",
        url: url,
      })
        .done(function (data) {
          var r = data.r;
          var msg = data.s;

          if (r === "1") {
            //  this.postresult = "登陆成功！";
            // this2.props.history.push("/");
            this2.setState({
              posterror: "",
              postresult: "Your new password reseted!",
            });
            this2.props.history.push(
              "/alert?message=Your new password reseted.&tourl=&urltitle=Home"
            );
          } else {
            //document.getElementById("result2").style.color = "red";
            this2.setState({
              posterror: msg,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }
}

export default Resetpass;
