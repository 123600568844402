import { fromJS } from "immutable";

const defaultState = fromJS({
  cpLb: [],
  adList: [],
  ggList: [],
  homeregad: [],
  bnewlist: [],
  //  dflblist: [],
});
const todo = (state = defaultState, action) => {
  switch (action.type) {
    case "change_home_data": {
      // const newState = JSON.parse(JSON.stringify(state));
      //newState.topicList = action.topicList;
      // newState.articleList = action.articleList;
      // newState.recommendList = action.recommendList;

      // return newState;

      return state.merge({
        cpLb: action.cpLb,
        adList: action.adList,
        ggList: action.ggList,
        homeregad: action.homeregad,
        bnewlist: action.bnewlist,
        // dflblist: action.dflblist,
      });
      //    return state.set('topicList',fromJS(action.topicList)).set('articleList',fromJS(action.articleList)).set('recommendList',fromJS(action.recommendList));
    }
    default: {
      return state;
    }
  }
};
export default todo;
