import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import { AJAROOT, Dollar, rentUnit } from "@/common/gdata/global.js";
import { Link } from "react-router-dom";
import "@/css/detail.css";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      result: "",
      error: "",
      showpanel: 0,
      zjgm: 0,
      sl: 1,

      selcsn: "",
      selcsv: "",
      csjiajia: 0,
      cpid: "1",
      title: "",
      subtitle: "",
      amount: 0,
      desc: "",
      price: 0,
      marketprice: 0,
      pinpai: "",
      anlibm: "",
      unit: "",
      weight: "",
      arrimg: ["/g/i/200708/181427_814.jpg", "/g/i/200708/181431_465.jpg"],
      cs: [],
    };
  }

  render() {
    const styles = {
      slide: {
        padding: 15,
        minHeight: 100,
        color: "#fff",
      },
      slide1: {
        backgroundColor: "#FEA900",
      },
      slide2: {
        backgroundColor: "#B3DC4A",
      },
      slide3: {
        backgroundColor: "#6AC0FF",
      },
    };

    const { cartnum } = this.props;

    let amountstr = "out of stock";
    let amount = this.state.amount * 1;
    if (amount > 0) amountstr = amount + " available";
    return (
      <div className="container wrap">
        <div className="row " style={{ height: "55px", lineHeight: "55px" }}>
          <div
            className="col-xs-2 col-sm-2 col-md-2 col-lg-2"
            onClick={() => {
              window.history.back(-1);
            }}
          >
            <span className="iconfont fanhui">&#xe625;</span>
          </div>
          <div
            className="col-xs-8 col-sm-8 col-md-8 col-lg-8 textcenter "
            style={{ fontSize: "22px", fontWeight: "bolder" }}
          >
            {this.state.title} {this.state.anlibm}
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 cartimg">
            <Link to="/cart" key="mycart">
              <span className="icon iconfont" style={{ fontSize: "40px" }}>
                &#xe70b;
              </span>
              <span className="cartnum">{cartnum}</span>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className={this.state.subtitle === "" ? "nodis" : "subtitle"}>
            {this.state.subtitle}
          </div>
        </div>
        <div className="row">
          <div className={this.state.pinpai === "" ? "nodis" : "subtitle"}>
            Brand: {this.state.pinpai}
          </div>
        </div>
        <div className="row textcenter ">
          <div style={{ height: "25px", lineHeight: "25px" }}>
            <span className="price" style={{ paddingLeft: "15px" }}>
              {Dollar}
              {this.state.price * 1 + this.state.csjiajia * 1}
            </span>
            <span> / {rentUnit}</span>
            <span
              style={{ paddingLeft: "15px" }}
              className={amount === 0 ? "outof" : "cpcommon"}
            >
              {amountstr}
            </span>
          </div>
        </div>
        <div className="row textcenter" style={{ padding: "15px" }}>
          <div>
            {/* <button
              className="cart"
              style={{ backgroundColor: "#337b70" }}
              onClick={() => this.showPanel(1, 1)}
            >
              <span className="iconfont">&#xe621;</span>{" "}
              <span className="c1">直接下单</span>
            </button> */}
            　　
            <button
              className={this.state.result === "Done" ? "nodis" : "cart"}
              onClick={() => this.showPanel(1, 0)}
            >
              <span className="iconfont">&#xe70b;</span>{" "}
            </button>
            <span
              className={this.state.result === "Done" ? "" : "nodis"}
              style={{ fontWeight: "bold", fontSize: "20px" }}
            >
              Added Successfully!{" "}
              <a href="/cart" style={{ color: "#ff6633" }}>
                {" "}
                Checkout Now!
              </a>
            </span>
          </div>
        </div>
        <div className="row textleft">
          <div dangerouslySetInnerHTML={{ __html: this.state.desc }}></div>
        </div>
        <div
          className="row"
          style={{
            textAlign: "center",
          }}
        >
          <div id="myCarousel" className="carousel slide">
            <ol className="carousel-indicators">
              {this.state.arrimg.map((item, index) => {
                return (
                  <li
                    key={item["adtitle"]}
                    data-target="#myCarousel"
                    data-slide-to={"" + index + ""}
                    className={index === 0 ? "active" : ""}
                  ></li>
                );
              })}
            </ol>

            <div className="carousel-inner">
              {this.state.arrimg.map((item, index) => {
                return (
                  <div
                    className={index === 0 ? "item active" : "item"}
                    key={item}
                    style={{}}
                  >
                    <img src={AJAROOT + item} style={{ margin: "auto" }} />
                  </div>
                );
              })}
            </div>

            <a
              className={
                this.state.arrimg.length > 1
                  ? "left carousel-control"
                  : "left carousel-control nodis"
              }
              href="#myCarousel"
              role="button"
              data-slide="prev"
              style={{ backgroundImage: "none" }}
            >
              <span
                className="glyphicon glyphicon-chevron-left"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className={
                this.state.arrimg.length > 1
                  ? "right carousel-control"
                  : "right carousel-control nodis"
              }
              href="#myCarousel"
              role="button"
              data-slide="next"
              style={{ backgroundImage: "none" }}
            >
              <span
                className="glyphicon glyphicon-chevron-right"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        {/* <div className="row">
          <div className="datu">
            <AutoPlaySwipeableViews
              interval={3000}
              onChangeIndex={this.handleChangeIndex}
            >
              {this.state.arrimg.map((item, index) => {
                return <img src={AJAROOT + item} alt="" key={index}></img>;
              })}
            </AutoPlaySwipeableViews>
          </div>
        </div> */}
        <div className="row textcenter cartpanel1">
          <div
            className="cartpanel"
            style={{ display: this.state.showPanel === 1 ? "block" : "none" }}
          >
            <div className="row top1">
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{}}>
                <img src={AJAROOT + this.state.arrimg[0]} alt=""></img>
              </div>
              <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 paneltitle">
                <div className="row textleft"> {this.state.title}</div>

                <div
                  className="row textleft del"
                  style={{ textAlign: "left" }}
                ></div>
                <div className="row textleft">
                  {Dollar}
                  {this.state.price * 1 + this.state.csjiajia * 1} /{rentUnit}
                </div>
                <div className="row textleft sl">
                  <button onClick={() => this.changeSl(-1)}> - </button>　
                  <button style={{ width: "35px" }}>{this.state.sl}</button>　
                  <button onClick={() => this.changeSl(1)}> + </button>
                </div>
              </div>
              <div
                className="col-xs-1 col-sm-1 col-md-1 col-lg-1 close"
                onClick={() => this.showPanel(0, 0)}
              >
                <span>X</span>
              </div>
            </div>
            {this.state.cs.map((item, index) => {
              return (
                <div className="row textleft cs" key={index}>
                  <div
                    className="textright csn"
                    style={{ width: "30%", paddingRight: "10px" }}
                  >
                    {item["name"]}：
                  </div>
                  <div className="textleft" style={{ width: "65%" }}>
                    {item["ops"].map((item2, index2) => {
                      return (
                        <span
                          onClick={() => this.changeCs(index, index2)}
                          className={item2["chk"] === 1 ? "csv cur" : "csv"}
                          key={index2}
                        >
                          {item2["val"]}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div
              className={
                this.state.error === "" ? "nodis" : "row textcenter error"
              }
            >
              {this.state.error}
            </div>
            <div className="row textcenter">
              <button
                className="panelsubmit"
                style={{ width: "95%" }}
                onClick={() => this.submitCart()}
              >
                <span className="iconfont" style={{ fontSize: "25px" }}>
                  &#xe70b;
                </span>{" "}
                <span className="c1" style={{ fontSize: "25px" }}>
                  Add to cart
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getData(cpid) {
    var geturl = AJAROOT + "/getaja/view.aspx?id=" + cpid + "";

    axios.get(geturl).then((res) => {
      const result = res.data;
      // this.setState({}); /**/
      //  console.log(result);
      let selcsn = "";
      let selcsv = "";
      let csjiajia = 0;
      document.title = result["cpro"]["title"] + result["cpro"]["subtitle"];
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", result["cpro"]["pagedesc"]);
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", result["cpro"]["keyword"]);

      //  document.querySelector('meta[name=“description”]’).setAttribute(‘content’,‘BBBBBB’);
      //document.querySelector(‘meta[name=“keywords”]’).setAttribute(‘content’,‘CCCCCCCCC’);
      if (result["cs"].length > 0) {
        selcsn = result["cs"][0]["name"];
        selcsv = result["cs"][0]["ops"][0]["val"];
        let cs = result["cs"];
        for (let i0 = 0; i0 < cs.length; i0++) {
          for (let i = 0; i < cs[i0]["ops"].length; i++) {
            if (cs[i0]["ops"][i]["chk"] === 1) {
              csjiajia = csjiajia + cs[i0]["ops"][i]["jia"] * 1;
            }
          }
        }
      }

      this.setState({
        cpid: cpid,
        title: result["cpro"]["title"],
        subtitle: result["cpro"]["subtitle"],
        desc: result["cpro"]["desc"],
        amount: result["cpro"]["amount"],
        price: result["cpro"]["price"],
        marketprice: result["cpro"]["marketprice"],
        pinpai: result["cpro"]["pinpai"],
        unit: result["cpro"]["unit"],
        weight: result["cpro"]["weight"],
        anlibm: result["cpro"]["anlibm"],
        arrimg: result["cpro"]["arrimg"],
        cs: result["cs"],
        selcsn: selcsn,
        selcsv: selcsv,
        csjiajia: csjiajia,
      });
    });
  }
  componentDidMount() {
    //  $("#bottomer").css("display", "none");
    const { cpid } = this.props.match.params;

    this.getData(cpid);
  }

  componentWillUnmount() {
    // $("#bottomer").css("display", "block");
    // console.log("componentWillUnmount");
  }

  componentWillUpdate() {
    //  console.log("componentWillUpdate");
  }
  componentDidUpdate() {}
  submitCart() {
    const that = this;
    const { myusername, ukey } = this.props;

    const encodeukey = encodeURIComponent(ukey);

    if (myusername === "") {
      this.setState({
        error: "Please login",
      });
    } else {
      var _cs = new Array(0);
      for (var j = 0; j < this.state.cs.length; j++) {
        var csitem = this.state.cs[j].ops;
        //var _tmpcs=new Array(2);
        var name = this.state.cs[j].name;
        for (var g = 0; g < csitem.length; g++) {
          var chk = csitem[g].chk;

          var val = csitem[g].val;
          if (chk === 1) {
            var _cs2 = {};
            _cs2.name = name;
            _cs2.val = val;
            _cs.push(_cs2);
          }
        }
      }
      const postdata = {
        cpnum: this.state.sl,
        cpid: this.state.cpid,
        cs: _cs,
      };

      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/tocart.aspx?t=add2&uid=" +
          myusername +
          "&ukey=" +
          encodeukey +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (data) {
          var r = data.r;
          var result = data.s;
          console.log(data);
          if (r === 1) {
            that.setState({
              result: "Done",
            });
            var cartnum = data.cartnum;
            //  this.postresult = "登陆成功！";
            const action = {
              type: "change_cart_num",
              cartnum: cartnum,
            };

            that.props.changeCartNum(action);
            if (that.state.zjgm === 1) {
              // this2.props.changeLoginData(action);
              that.props.history.push("/cart");
            } else that.showPanel(0, 0);
          } else {
            that.setState({
              error: result,
            });
            if (result.indexOf("Please login") >= 0) {
              const action = {
                type: "change_login_data",
                username: "",
                ukey: "",
                usertype: 0,
                usergrade: 1,
                cartnum: 0,
              };
              that.props.changeLoginData(action);
            }
            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {});
    }
  }
  changeSl(add) {
    var sl = this.state.sl;
    const amount = this.state.amount * 1;
    sl = sl * 1 + add * 1;
    if (sl <= 0) sl = 0;
    if (sl > amount) sl = amount;
    this.setState({
      sl: sl,
    });
  }
  showPanel(isshow, zjgm) {
    this.setState({
      showPanel: isshow,
      zjgm: zjgm,
      sl: 1,
    });
  }
  changeCs(index, index2) {
    var cs = this.state.cs;
    var csjiajia = 0;
    for (let i = 0; i < cs[index]["ops"].length; i++) {
      cs[index]["ops"][i]["chk"] = 0;
    }
    cs[index]["ops"][index2]["chk"] = 1;

    for (let i0 = 0; i0 < cs.length; i0++) {
      for (let i = 0; i < cs[i0]["ops"].length; i++) {
        if (cs[i0]["ops"][i]["chk"] === 1) {
          csjiajia = csjiajia + cs[i0]["ops"][i]["jia"] * 1;
        }
      }
    }

    this.setState({
      csjiajia: csjiajia,
      cs: cs,
    });
  }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    ukey: state.getIn(["gdata", "ukey"]),
    cartnum: state.getIn(["gdata", "cartnum"]),
  };
};
const mapDispatch = (dispath) => ({
  changeCartNum(action) {
    dispath(action);
  },
  changeLoginData(action) {
    dispath(action);
  },
});
export default connect(mapStateToProps, mapDispatch)(Detail);
