export const AJAROOT = "https://www.gracioushome.ca";
//export const AJAROOT = "http://localhost:8705";
export const SiteName = "Gracious Home";
export const SiteUrl = "http://localhost:3000";
export const Dollar = "CA$";
export const Color1 = "#337b70";
export const Color2 = "#ff6633";
export const rentUnit = "month";
export const mileFee = 0.5;
export const insuranceHl = 0.02;
export const FromZip = "M1V4T1";
export const Tax = 0.13;
export const PriceBl1 = 1.4;
export const PriceBl2 = 1.7;
export const PickUpDiff = 5;
export const RememberMe = 0;
export function UserType() {
  return {
    "-1": "未激活",
    0: "普通会员",
    1: "激活会员",
  };
}

export function GUserGrade() {
  return {
    1: "Grade 1",
    2: "Grade 2",
    3: "Grade 3",
    4: "Grade 4",
    5: "Grade 5",
    6: "Grade 6",
  };
}
