import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import { AJAROOT } from "@/common/gdata/global.js";
import "@/css/global.css";
class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postresult: "",
    };
  }
  render() {
    // const { postresult } = this.props;
    //  console.log("render");
    return (
      <div className="textcenter" style={{ padding: "20px" }}>
        Logout...
      </div>
    );
  }
  componentDidMount() {
    this.logout();
  }

  logout() {
    const { myusername, ukey } = this.props;
    const encodeukey = encodeURIComponent(ukey);

    const this2 = this;

    $.ajax({
      type: "GET",

      url:
        AJAROOT +
        "/getaja/xx.aspx?action=logout&uid=" +
        myusername +
        "&ukey=" +
        encodeukey +
        "",
    })
      .done(function (data) {
        var msg = data.msg;
        console.log("logout" + data);
        if (msg === "ok") {
          cookie.remove("username");
          cookie.remove("openid");
          //  this.postresult = "登陆成功！";
          const action = {
            type: "change_login_data",
            username: "",
            ukey: "",
            usertype: 0,
            usergrade: 1,
            cartnum: 0,
          };
          this2.props.changeLoginData(action);
          this2.props.history.push("/");
        } else {
          this2.setState({
            postresult: msg,
          });

          //  document.getElementById("result2").innerHTML = msg;
        }
      })
      .fail(function (xhr, textStatus, errorThrown) {});
  }
}
const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    ukey: state.getIn(["gdata", "ukey"]),
  };
};
const mapDispatch = (dispatch) => ({
  changeLoginData(action) {
    dispatch(action);
  },
});

export default connect(mapStateToProps, mapDispatch)(withRouter(Logout));
