import { fromJS } from "immutable";

const defaultState = fromJS({
  lblist: [],

  cplists: [],
  totalpage: 0,
  num: 0,
  type1: "",
  type2: "",
  type1id: "",
  type2id: "",
  typeid: "",
  kwd: "",
  price: "",
  page: 1,
  pricearr: [],
  viewstyle: "window",
});
const todo = (state = defaultState, action) => {
  switch (action.type) {
    case "change_type_data": {
      // const newState = JSON.parse(JSON.stringify(state));
      //newState.topicList = action.topicList;
      // newState.articleList = action.articleList;
      // newState.recommendList = action.recommendList;

      // return newState;
      return state.merge({
        lblist: action.lblist,
        totalpage: action.totalpage,
        num: action.num,
        cplists: action.cplists,
        type1: action.type1,
        type2: action.type2,
        type1id: action.type1id,
        type2id: action.type2id,
        typeid: action.typeid,
        kwd: action.kwd,
        price: action.price,
        page: action.page,
        pricearr: action.pricearr,
      });
      //    return state.set('topicList',fromJS(action.topicList)).set('articleList',fromJS(action.articleList)).set('recommendList',fromJS(action.recommendList));
    }
    case "change_type_view": {
      // const newState = JSON.parse(JSON.stringify(state));
      //newState.topicList = action.topicList;
      // newState.articleList = action.articleList;
      // newState.recommendList = action.recommendList;

      // return newState;
      return state.merge({
        viewstyle: action.viewstyle,
      });
      //    return state.set('topicList',fromJS(action.topicList)).set('articleList',fromJS(action.articleList)).set('recommendList',fromJS(action.recommendList));
    }
    default: {
      return state;
    }
  }
  //return state;
};
export default todo;
