import { fromJS } from "immutable";
let gdata = sessionStorage.getItem("gdata")
  ? JSON.parse(sessionStorage.getItem("gdata"))
  : {
      username: "",
      ukey: "",
      usergrade: 1,
      usertype: 0,
      cartnum: 0,
    };
let cartnum = sessionStorage.getItem("cartnum")
  ? sessionStorage.getItem("cartnum")
  : 0;
const defaultState = fromJS({
  username: gdata["username"],
  ukey: gdata["ukey"],
  usergrade: gdata["usergrade"],
  usertype: gdata["usertype"],
  cartnum: cartnum,
});
const Todo = (state = defaultState, action) => {
  switch (action.type) {
    case "change_login_data": {
      sessionStorage.setItem("gdata", JSON.stringify(action));
      sessionStorage.setItem("cartnum", action.cartnum);
      return state.merge({
        username: action.username,
        ukey: action.ukey,
        usergrade: action.usergrade,
        usertype: action.usertype,
        cartnum: action.cartnum,
      });
    }

    case "change_cart_num": {
      sessionStorage.setItem("cartnum", action.cartnum);

      return state.merge({
        cartnum: action.cartnum,
      });
    }
    default: {
      return state;
    }
  }
};
export default Todo;
